<template>
  <div class="custom-select-start margin-bottom20">
    <div class="custom-select-start-box" @click="toggleDropdown">
      <label class="custom-select-start-label">Виды</label>

      <!-- Выбранные элементы в виде тегов -->
      <div class="selected-tags">
        <span v-for="(type, index) in selectedTypes" :key="index" class="selected-tag">
          {{ type.name }}
          <span class="remove-tag" @click.stop="removeType(index)">✕</span>
        </span>
      </div>

      <span class="custom-select-start-arrow" :class="{ open: isOpen }">
        <img src="../../assets/svg/ArrowDown.svg" alt="ArrowDown">
      </span>
    </div>

    <!-- Выпадающий список -->
    <ul v-if="isOpen" class="custom-select-start-dropdown">
      <li
          v-for="(type, index) in availableTypes"
          :key="index"
          @click="toggleType(type)"
          :class="{ active: selectedTypes.some(t => t.id === type.id) }"
      >
        {{ type.name }}
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "MultiSelectPerformedTypes",
    props: {
      performedTypes: {
        type: Array,
        required: true,
        default: () => []
      }
    },
    data() {
      return {
        isOpen: false,
        selectedTypes: []
      };
    },
    computed: {
      availableTypes() {
        return this.performedTypes;
      }
    },
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      },
      toggleType(type) {
        const index = this.selectedTypes.findIndex(t => t.id === type.id);
        if (index === -1) {
          this.selectedTypes.push(type);
        } else {
          this.selectedTypes.splice(index, 1);
        }
        this.$emit("update:selectedTypes", this.selectedTypes);
      },
      removeType(index) {
        this.selectedTypes.splice(index, 1);
        this.$emit("update:selectedTypes", this.selectedTypes);
      },
      closeDropdown(event) {
        if (!this.$el.contains(event.target)) {
          this.isOpen = false;
        }
      }
    },
    mounted() {
      document.addEventListener("click", this.closeDropdown);
    },
    beforeDestroy() {
      document.removeEventListener("click", this.closeDropdown);
    }
  };
</script>

<style scoped>

.custom-select-start-box {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 6px 10px;
  background: white;
  position: relative;
  cursor: pointer;
  min-height: 44px;
  flex-wrap: wrap;
}

.custom-select-start-label {
  font-size: 12px;
  color: #2d0b00;
}

.custom-select-start-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s ease-in-out;
}

.custom-select-start-arrow.open {
  transform: translateY(-50%) rotate(180deg);
}

/* Контейнер для тегов */
.selected-tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
}

/* Теги */
.selected-tag {
  background: rgba(0, 123, 255, 0.1);
  color: #007bff;
  padding: 4px 8px;
  border-radius: 15px;
  margin-right: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

/* Кнопка удаления тега */
.remove-tag {
  margin-left: 6px;
  cursor: pointer;
  font-weight: bold;
  color: #007bff;
}

.custom-select-start-dropdown {
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: 4px;
  padding: 0;
  list-style: none;
  max-height: 250px;
  overflow-y: auto;
  z-index: 10;
}

/* Опции */
.custom-select-start-dropdown li {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #2d0b00;
}

/* Подсветка активной опции */
.custom-select-start-dropdown li:hover,
.custom-select-start-dropdown li.active {
  background: #f0f0f0;
  color: black;
}
</style>
