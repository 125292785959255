<template>
  <div class="button-container">
    <div class="button-container__delete">
      <button class="button-mini-tab red" v-if="value" @click="edit" @mouseover="showTooltip('Отмена')" @mouseleave="hideTooltip">
        <img src="../../assets/svg/false.svg">
      </button>
    </div>
    <!-- Кнопка редактирования -->
    <button class="button-mini-tab gray" @click="edit" @mouseover="showTooltip('Редактировать')" @mouseleave="hideTooltip">
      <img src="../../assets/svg/pencil.svg">
    </button>

    <!-- Кнопка удаления -->
    <button class="button-mini-tab red" @click="remove" @mouseover="showTooltip('Удалить')" @mouseleave="hideTooltip">
      <img src="../../assets/svg/delete.svg">
    </button>

    <!-- Кнопка сохранения -->
    <button class="button-mini-tab green" @click="save" @mouseover="showTooltip('Сохранить')" @mouseleave="hideTooltip">
      <img src="../../assets/svg/save.svg">
    </button>

    <div v-if="tooltip.visible" :style="tooltipStyle" class="tooltip">{{ tooltip.text }}</div>
  </div>
</template>

<script>
  import { actionTypesStartList } from '@/store/modules/createStartList'

  export default {
    name: "CardPerformanceTab",
    data() {
      return {
        tooltip: {
          visible: false,
          text: '',
          x: 0,
          y: 0
        },
      };
    },
    props: {
      item_flow: Object,
      selectedDate: String,
      value: Boolean
    },
    computed: {
      tooltipStyle() {
        return {
          top: `${this.tooltip.y + 50}px`,
          left: `${this.tooltip.x + 10}px`
        };
      }
    },
    methods: {
      edit() {
        this.$emit('input', !this.value);
        console.log("Редактирование", this.isEditing);
      },
      remove() {
        if(this.item_flow.listType === 'FLOW') {
          this.deleteFlow()
        } else {
          this.deleteBreak()
        }
      },
      // Удаление потока
      deleteFlow() {
        this.$store
            .dispatch(actionTypesStartList.deleteFlowProtocol, {
              flowId: this.item_flow.flowId
            })
            .then(() => {
              this.getStartList()
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      deleteBreak() {
        this.$store
            .dispatch(actionTypesStartList.deleteBreakFlowProtocol, {
              breakId: this.item_flow.breakId
            })
            .then(() => {
              this.getStartList()
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      save() {
        this.$emit('save');
        console.log("Сохранение");
      },
      showTooltip(text) {
        this.tooltip.text = text;
        this.tooltip.visible = true;
      },
      hideTooltip() {
        this.tooltip.visible = false;
      },
      getStartList() {
        this.$store
            .dispatch(actionTypesStartList.getStartListPerformance, {
              competitionId: this.$route.params.id,
              startDate: this.selectedDate,
            })
            .then(() => {})
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
    },

  };
</script>

<style scoped>
.button-container {
  display: flex;
  gap: 8px;
  position: relative;
}

.button-mini-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s, box-shadow 0.2s;
}

.button-mini-tab:hover {
  transform: scale(1.1);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.button-mini-tab:active {
  transform: scale(0.95);
}

.button-mini-tab.gray {
  border-color: gray;
  color: gray;
}
.button-mini-tab.gray:hover {
  background-color: #f0f0f0;
}
.button-mini-tab.gray:active {
  background-color: #d9d9d9;
}

.button-mini-tab img {
  width: 20px;
  height: 20px;
}

.button-mini-tab.red {
  border-color: #d9534f;
  color: #d9534f;
}
.button-mini-tab.red:hover {
  background-color: #ffe5e5;
}
.button-mini-tab.red:active {
  background-color: #ffcccc;
}

.button-mini-tab.green {
  border-color: #09b945;
  color: #09b945;
}
.button-mini-tab.green:hover {
  background-color: #e5ffe5;
}
.button-mini-tab.green:active {
  background-color: #ccffcc;
}

.tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  pointer-events: none;
}
</style>
