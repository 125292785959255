<template>
  <div class="button-container"  v-if="this.item_flow.breakId === null">
    <!-- Кнопка объединения с нижним -->
    <button class="button-tab gray" @click="mergeWithLower">
      <img src="../../assets/svg/merge-right-arrows.svg" class="button-icon" />
      <span>Объединить с нижним</span>
    </button>

    <!-- Кнопка добавления спортсмена -->
    <button class="button-tab gray" @click="addAthlete" >
      <img src="../../assets/svg/add.svg" class="button-icon" />
      <span>Добавить спортсмена</span>
    </button>
  </div>
</template>

<script>
  export default {
    name: "AppMergeAddAthlete",
    props: {
      item_flow: Object,
      selectedDate: String,
      value: Boolean
    },
    methods: {
      mergeWithLower() {
        this.$emit("mergeWithLower");
      },
      addAthlete() {
        this.$emit("add-athlete");
      }
    }
  };
</script>

<style scoped>
.button-container {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
}

.button-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s, box-shadow 0.2s;
  gap: 8px;
}
.button-tab:hover {
  transform: scale(1.02);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.button-tab:active {
  transform: scale(0.95);

}
.button-tab img {
  width: 20px;
  height: 20px;
}

.button-tab.gray {
  border-color: gray;
  color: gray;
  background-color: white;
}
.button-tab.gray:hover {
  background-color: #f0f0f0;
}
.button-tab.gray:active {
  background-color: #d9d9d9;
  color: black;
}
.button-tab.black {
  border-color: black;
  color: black;
  background-color: white;
}
.button-tab.black:hover {
  background-color: #333;
  color: white;
}
.button-tab.black:active {
  background-color: #1a1a1a;
  color: white;
}

.button-tab.green {
  border-color: #09b945;
  color: darkgreen;
  background-color: white;
}
.button-tab.green:hover {
  background-color: #e5ffe5;
}
.button-tab.green:active {
  background-color: #ccffcc;
}
</style>
