<template>
  <div class="arrow-container">
    <!-- Кнопка вверх -->
    <div class="arrow-button arrow-up" @click="upFlow" :class="classDisable"
         @mouseover="showTooltip('Поднять поток наверх', $event)"
         @mouseleave="hideTooltip">
      <div class="arrow-icon">↑</div>
    </div>

    <!-- Кнопка вниз -->
    <div class="arrow-button arrow-down" @click="downFlow"
         @mouseover="showTooltip('Опустить поток вниз', $event)"
         @mouseleave="hideTooltip">
      <div class="arrow-icon">↓</div>
    </div>

    <!-- Тултип -->
    <div v-if="tooltip.visible" class="tooltip" :style="tooltipStyle">
      {{ tooltip.text }}
    </div>
  </div>
</template>

<script>
  export default {
    name: "UpDownTab",
    data() {
      return {
        tooltip: {
          visible: false,
          text: '',
          x: 0,
          y: 0
        }
      };
    },
    computed: {
      tooltipStyle() {
        return {
          top: `${this.tooltip.y + 50}px`,
          left: `${this.tooltip.x + 10}px`,
        };
      },
      classDisable: function () {
        return {
          'disable-arrow': this.item_flow.flowNumber < 2,
        }
      },
    },
    props: {
      item_flow: Object,
    },
    methods: {
      upFlow() {
        this.$emit("upFlow");
      },
      downFlow() {
        this.$emit("downFlow");
      },
      showTooltip(text, event) {
        if (event && event.clientX !== undefined && event.clientY !== undefined) {
          this.tooltip.text = text;
          this.tooltip.visible = true;
          // this.tooltip.x = event.clientX;
          // this.tooltip.y = event.clientY;
        }
      },
      hideTooltip() {
        this.tooltip.visible = false;
      }
    }
  };
</script>

<style scoped>
.arrow-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  position: relative;
}

.arrow-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid gray;
  background-color: white;
  color: gray;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s, box-shadow 0.2s;
}

.arrow-button:hover {
  background-color: #f0f0f0;
  transform: scale(1.1);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.arrow-button:active {
  background-color: #d9d9d9;
  transform: scale(0.95);
}

.arrow-icon {
  font-size: 20px;
}

.tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  pointer-events: none;
}
</style>
