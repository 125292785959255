<template>
  <div class="home">
    <template v-if="token === ''">
      <Header message="Соревнования" />
    </template>

  <div class="main-content">
    <div class="loader-overlay" v-if="isLoading || isLoadingNotAuth ">
      <div class="loader"></div>
    </div>
    <template v-if="token">
      <Header message="Соревнования" />
    </template>
    <BreadCrumps/>
    <div class="detail-competition__container" v-if="item !== null">
      <div class="detail-competition__card">
        <div class="detail-competition__card-poster">
          <img
            v-if="item.urlImage !== null"
            class="preview-block__img"
            :src="`${item.urlImage}`"
            alt="foto"
          />
          <img v-else src="../assets/img/poster.png" alt="poster" />
        </div>
        <div class="detail-competition__card-info">
          <div class="detail-competition__cart-info-title">
            <p class="title-text">
              {{ item.title }}
            </p>
          </div>
          <div class="detail-competition__cart-info-text">
            <p class="text-label">Место проведения:</p>
            <p class="text-description">
              {{ item.place }}
            </p>
          </div>
          <div class="detail-competition__cart-info-date">
            <div class="detail-competition__cart-info-date-from">
              <p class="text-label">Дата проведения:</p>
              <p class="text-description">
                {{ dateOfFromComp }}-{{ dateOfToComp }}
              </p>
            </div>
            <div class="detail-competition__cart-info-date-to">
              <p class="text-label">Приём заявок до:</p>
              <p class="text-description">{{ dateOfPrismApp }}</p>
            </div>
          </div>
          <div class="detail-competition__wrap-btn">
            <!-- Проверяем наличие токена -->
            <template v-if="token !== ''">
              <!-- Блок кнопок для заявок -->
              <div v-if="item.status === 'CLOSE_EDIT_APPLICATION'" class="detail-competition__end-reg">
                <div class="message-about-empty empty">
                  <p class="message-about-empty__text">Прием заявок окончен!</p>
                </div>
                <div class="detail-competition__btn">
                  <button class="mandated__tag active" @click="continueRegister()">
                   <p class="button__img" >✏️</p>
                    Редактировать заявку
                  </button>
                </div>
              </div>

              <div v-if="item.status === 'OPEN_NOT_APPLICATION'" class="detail-competition__btn">
                <button class="mandated__tag active" @click="continueDraft()">
                  Подать заявку
                </button>
              </div>

              <div v-if="item.status === 'OPEN_EDIT_APPLICATION'" class="detail-competition__btn">
                <button class="mandated__tag active" @click="continueRegister()">
                  Редактировать заявку
                </button>
              </div>

              <div v-if="item.status === 'CLOSE_NOT_APPLICATION'" class="message-about-empty empty">
                <p class="message-about-empty__text">Прием заявок окончен!</p>
              </div>
            </template>
            <!-- Если нет токена, отображаем кнопку для входа -->
            <template v-else>
              <div class="detail-competition__btn">
                <button class="button" @click="login()">
                  Войти в аккаунт
                </button>
              </div>
            </template>
          </div>

        </div>
      </div>
      <div class="detail-competition-description">
        <div class="detail-competition-description__title">
          <p class="title-text">Описание:</p>
        </div>
        <div class="detail-competition-description__block">
          <p class="text-description">
            {{ item.description }}
          </p>
        </div>
      </div>
      <div class="detail-competition-description" v-if="item.documents && item.documents.length > 0">
        <div class="detail-competition-category__title">
          <p class="title-text">Прикрепленные документы:</p>
        </div>
        <div class="detail-competition-description__block">
          <div class="documents-wrap">
          <div class="documents__list">
            <div
                v-for="(doc) in item.documents"
                :key="doc.id"
                class="documents-item-block"
            >
              <div class="document-item-row__svg">
                <img v-if="doc.fileType === 'pdf'" src="../assets/svg/pdf-file.svg" alt="pdf icon">
                <img v-else-if="doc.fileType === 'doc' || doc.fileType === 'docx' " src="../assets/svg/doc-file.svg" alt="word icon">
                <img v-else-if="doc.fileType === 'pptx'" src="../assets/svg/pptx-file.svg" alt="pptx icon">
                <img v-else-if="doc.fileType === 'xls' || doc.fileType === 'xlsx' " src="../assets/svg/xlsx-file.svg" alt="xlsx icon">
                <!-- <span v-else>Loading...</span> -->
              </div>
              <div class="document-item-row__svg">
                <a :href="doc.url" target="_blank" class="document-item__link">
                  {{ doc.fileName }}
                </a>
              </div>
            </div>
            <!-- Показать текст, если документов нет -->
            <div v-if="item.documents.length === 0" class="documents__placeholder">
              Документы отсутствуют
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="detail-competition-category">
        <div class="detail-competition-category__title">
          <p class="title-text">Категории спортсменов:</p>
        </div>
        <div class="detail-competition-category__categ">
          <div class="detail-competition-category__label">
            <p class="text-label">Индивидуальная программа</p>
          </div>
          <div class="detail-competition-category__wrap">
            <item-individual-categories
              v-for="item in item.individualCategory"
              :key="item.id"
              v-bind:item_ind_categ="item"
            />
          </div>
          <!-- Групповые упражнения -->
          <div  class="detail-competition-category__label2" v-if="groupCategories.length">
            <p class="text-label">Групповые упражнения</p>
            <div class="detail-competition-category__wrap">
              <item-group-categ
                  v-for="item in groupCategories"
                  :key="item.id"
                  :item_group_categ="item"
              />
            </div>
          </div>

          <!-- Дуэты -->
          <div  class="detail-competition-category__label2" v-if="duoCategories.length">
            <p class="text-label">Дуэты</p>
            <div class="detail-competition-category__wrap">
              <item-group-categ
                  v-for="item in duoCategories"
                  :key="item.id"
                  :item_group_categ="item"
              />
            </div>
          </div>

          <!-- Трио -->
          <div  class="detail-competition-category__label2" v-if="trioCategories.length">
            <p class="text-label">Трио</p>
            <div class="detail-competition-category__wrap">
              <item-group-categ
                  v-for="item in trioCategories"
                  :key="item.id"
                  :item_group_categ="item"
              />
            </div>
          </div>



<!--          <div class="detail-competition-category__label2" v-if="item.groupResponses.length">-->
<!--            <p class="text-label">Групповые упражнения</p>-->
<!--          </div>-->
<!--          <div class="detail-competition-category__wrap">-->
<!--            <item-group-categ-->
<!--              v-for="item in item.groupResponses"-->
<!--              :key="item.id"-->
<!--              v-bind:item_group_categ="item"-->
<!--            />-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <modal-application
      v-if="isOpenModalApplication"
      :item_app="itemOpenApplication"
      @close="isOpenModalApplication = false"
    />
  </div>
  </div>
</template>

<script>
import ItemGroupCateg from "../components/DetailCard/ItemGroupCateg.vue";
import ItemIndividualCategories from "../components/DetailCard/ItemIndividualCategories.vue";
import Header from "../components/Header.vue";
import { mapState } from "vuex";
import { actionTypes } from "@/store/modules/competition";
import ModalApplication from "../components/Modal/ModalApplication.vue";
import BreadCrumps from '@/components/BreadCrumps.vue'
import { actionTypesWithoutAuth } from '@/store/modules/withoutAuthorization'

export default {
  components: {
    BreadCrumps,
    Header,
    ItemGroupCateg,
    ItemIndividualCategories,
    ModalApplication,
  },
  name: "AppItemDetailedCardCompetition",
  data() {
    return {
      isOpenModalApplication: false,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.competition.isLoading,
      isLoadingNotAuth: (state) => state.withoutAuth.isLoading,
      item: (state) => state.auth.token !== '' ? state.competition.itemCompetition : state.withoutAuth.itemCompetitionNotAuth,
      token: (state) => state.auth.token,
    }),
    dateOfFromComp() {
      return this.$moment(this.item.fromDate)
        .locale("ru")
        .format("LL");
    },
    dateOfToComp() {
      return this.$moment(this.item.toDate)
        .locale("ru")
        .format("LL");
    },
    dateOfPrismApp() {
      return this.$moment(this.item.applicationsBefore)
        .locale("ru")
        .format("LL");
    },
    groupCategories() {
      return this.item.groupResponses.filter((item) => item.type === "GROUP");
    },
    duoCategories() {
      return this.item.groupResponses.filter((item) => item.type === "DUO");
    },
    trioCategories() {
      return this.item.groupResponses.filter((item) => item.type === "TRIO");
    },
  },
  methods: {
    continueDraft() {
      this.$router.push({
        name: "New-Application",
        params: { id: this.item.id },
      });
    },
    continueRegister() {
      this.$router.push({
        name: "Edit-Application",
        params: { id: this.item.id },
      });
    },
    login() {
      this.$router.push({
        name: "Login",
      });
    },
    openModalApp() {
      this.isOpenModalApplication = true;
    },

  },
  mounted() {
    if(this.token !== '') {
      this.$store
          .dispatch(actionTypes.idCompetition, this.$route.params.id)
          .then(() => {})
          .catch((er) => {
            console.log("Ошибка", er);
          });
    } else {
      this.$store
          .dispatch(actionTypesWithoutAuth.idCompetition, this.$route.params.id)
          .then(() => {})
          .catch((er) => {
            console.log("Ошибка", er);
          });
    }

  },
};
</script>

<style></style>
