<template>
  <div class="competition__item">
    <div
      :class="[
        this.item_comp.isFinished
          ? 'header__block-brown'
          : 'header__block-draft',
      ]"
    >
      <p class="header__text" v-if="this.item_comp.isFinished">
        Соревнования #{{ this.item_comp.id }}
      </p>
      <p class="header__text" v-else>Черновик #{{ this.item_comp.id }}</p>
    </div>
    <div class="info-about draft">
      <div class="">
        <div class="info-about__title">
          <p class="info-about__title-text">
            {{ this.item_comp.title }}
          </p>
        </div>
        <div class="info-about__description">
          <div class="title-description">
            <p class="title-description__title">Дата создания черновика:</p>
            <p class="title-description__text">
              {{ this.dateOfCreationMoment }}
            </p>
          </div>
          <div class="title-description">
            <p class="title-description__title">Последнее изменение:</p>
            <p class="title-description__text">{{ this.dateOfEditMoment }}</p>
          </div>
        </div>
      </div>
      <div class="competition__item-wrap">
        <div class="competition__item-btn-2" v-if="!this.item_comp.isFinished">
          <router-link
            :to="{
              name: 'Creation',
              params: { id: this.item_comp.id },
            }"
          >
            <button class="mandated__tag orange" @click="continueDraft()">
              Продолжить
            </button>
          </router-link>
        </div>
        <div class="competition__item-btn-2" v-else>
          <router-link
            :to="{
              name: 'Creation',
              params: { id: this.item_comp.id },
            }"
          >
            <button class="mandated__tag dark" @click="continueDraft()">Изменить</button>
          </router-link>
        </div>
        <div class="competition__item-trash">
          <img
            src="../assets/svg/trash.svg"
            alt="delete"
            @click="deleteDraft"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { actionTypes } from "@/store/modules/registrationComp";
export default {
  name: "AppItemCompetition",
  data() {
    return {};
  },
  props: {
    item_comp: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    dateOfEditMoment() {
      return this.$moment(this.item_comp.dateOfEdit).locale("ru").format("LLL");
    },
    dateOfCreationMoment() {
      return this.$moment(this.item_comp.dateOfCreation)
        .locale("ru")
        .format("LLL");
    },
  },
  methods: {
    //Удаление соревнование
    deleteDraft() {
      this.$store
        .dispatch(actionTypes.deleteCompetition, this.item_comp.id)
        .then(() => {
          this.$store
            .dispatch(actionTypes.allUserCompetition)
            .then(() => {})
            .catch((er) => {
              console.log("Ошибка", er);
            });
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    continueDraft() {
      this.$store
        .dispatch(actionTypes.getCompetitionItem, this.item_comp.id)
        .then(() => {})
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
  },
};
</script>

<style></style>
