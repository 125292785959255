<template>
  <div class="custom-select-start">
    <div class="custom-select-start-box" @click="toggleDropdown">
      <label class="custom-select-start-label">Округление времени</label>
      <span class="custom-select-start-selected">{{ selectedOption }}</span>
      <span class="custom-select-start-arrow" :class="{ open: isOpen }">
        <img src="../../assets/svg/ArrowDown.svg" alt="ArrowDown">
      </span>
    </div>
    <ul v-if="isOpen" class="custom-select-start-dropdown">
      <li
          v-for="(option, index) in options"
          :key="index"
          @click="selectOption(option)"
          :class="{ active: option === selectedOption }"
      >
        {{ option }}
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "AppInputRound",
    data() {
      return {
        isOpen: false,
        selectedOption: "Округлять",
        options: ["Округлять", "Не округлять"]
      };
    },
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      },
      selectOption(option) {
        this.selectedOption = option;
        this.isOpen = false;
      },
      closeDropdown(event) {
        if (!this.$el.contains(event.target)) {
          this.isOpen = false;
        }
      }
    },
    mounted() {
      document.addEventListener("click", this.closeDropdown);
    },
    beforeDestroy() {
      document.removeEventListener("click", this.closeDropdown);
    }
  };
</script>

<style scoped>

</style>
