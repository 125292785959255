import axios from "@/api/axios";

//Получение информации для составления стратового протокола
const aboutCompStartList = () => {
  return axios.get(``);
};
const getNominationsList = (competitionId) => {
  return axios.get(`/get/performedType/individual/${competitionId}`);
};
const getNominationGroupList = (competitionId) => {
  return axios.get(`/get/performedType/group/${competitionId}`);
};
const postAllInfoAboutStartList = (allInfo) => {
  return axios.post(`/create/protocol`, allInfo);
};
//NEW
const addFlowInProtocol = (data) => {
  return axios.post(`/add/protocol/flow`, data);
};
const deleteFlowProtocol = (data) => {
  return axios.post(`/delete/protocol/flow?flowId=${data.flowId}`);
};
const getStartListPerformance = (data) => {
  return axios.get(`/get/protocol/flows?competitionId=${data.competitionId}&startDate=${data.startDate}`);
};
const saveChangeAllFlowSubmit = (data) => {
  return axios.post(`/change/one/flow`, data);
}
const changePositionFlow = (data) => {
  return axios.post(`/update/flow/position?flowId=${data.flowId}&newPosition=${data.newPosition}`, data)
}
const changePositionBreak = (data) => {
  return axios.post(`/update/break/position?breakId=${data.breakId}&newPosition=${data.newPosition}`)
}
const addBreakFlowInProtocol =(data) => {
  return axios.post(`/add/break`, data);
}
const deleteBreakFlowProtocol = (data) => {
  return axios.post(`/delete/break?breakId=${data.breakId}`);
}

const joinFlowInStartList = (data) => {
  return axios.post(`/unite/flow`, data);
}
const allStartLists = (id) => {
  return axios.get(`/get/dates/competition?competitionId=${id}`);
}
const updateStartListTime = (data) => {
  return axios.post(`/change/time`, data);
}

const deleteSportsman = (data) => {
  return axios.post(`/delete/flow/sportsman`, data);
}

const changeProtocolType = (data) => {
  return axios.post(`/change/spinner`, data);
}

export default {
  aboutCompStartList,
  getNominationsList,
  postAllInfoAboutStartList,
  getNominationGroupList,
  addFlowInProtocol,
  deleteFlowProtocol,
  getStartListPerformance,
  addBreakFlowInProtocol,
  deleteBreakFlowProtocol,
  saveChangeAllFlowSubmit,
  changePositionFlow,
  changePositionBreak,
  joinFlowInStartList,
  allStartLists,
  updateStartListTime,
  deleteSportsman,
  changeProtocolType
};
