<template>
  <div class="competition__item">
    <div
      :class="[
        this.item_app.isFinished || this.item_app.isEndRegistration
          ? 'header__block-brown'
          : 'header__block',
      ]"
    >
      <!-- header__block-draft -->
      <p class="header__text">Заявка #{{ this.item_app.id }}</p>
    </div>
    <div class="info-about draft">

      <div class="">
        <div class="info-about__title">
          <p class="info-about__title-text">
            {{ this.item_app.title }}
          </p>
        </div>
        <div class="info-about__icon">
        <div class="info-about__icon-length" v-if="this.item_app.sportsmans && this.item_app.sportsmans.length">
          <img src="../../assets/svg/user-dark.svg" alt="user" />
          <p class="title-description__title">{{this.item_app.sportsmans.length}} Инд.</p>
        </div>
          <div class="info-about__icon-length" v-else>
            <img src="../../assets/svg/user-dark.svg" alt="user" />
            <p class="title-description__title">0 Инд.</p>
          </div>
          <div class="info-about__icon-length" v-if="this.item_app.groups && this.item_app.groups.length">
            <img src="../../assets/svg/groupUser.svg" alt="user" />
            <p class="title-description__title">{{this.item_app.groups.length}} Групп.</p>
          </div>
          <div class="info-about__icon-length" v-else>
            <img src="../../assets/svg/groupUser.svg" alt="user" />
            <p class="title-description__title">0 Групп.</p>
          </div>
          <div class="info-about__icon-length" v-if="this.item_app.duos && this.item_app.duos.length">
            <img src="../../assets/svg/groupUser.svg" alt="user" />
            <p class="title-description__title">{{this.item_app.duos.length}} Дуэт.</p>
          </div>
          <div class="info-about__icon-length" v-if="this.item_app.trios && this.item_app.trios.length">
            <img src="../../assets/svg/groupUser.svg" alt="user" />
            <p class="title-description__title">{{this.item_app.trios.length}} Трио.</p>
          </div>
          <div class="info-about__icon-length" v-if="this.item_app.judges && this.item_app.judges.length">
            <img src="../../assets/svg/auction.svg" alt="user" />
            <p class="title-description__title">{{this.item_app.judges.length}} Суд.</p>
          </div>
          <div class="info-about__icon-length" v-else>
            <img src="../../assets/svg/auction.svg" alt="user" />
            <p class="title-description__title">0 Суд.</p>
          </div>
        </div>
        <div class="validation" v-if="this.item_app.isEndRegistration">
          <p class="validation__text">
            *Прием заявок окончен, добавление спортсменов невозможно!
          </p>
        </div>
        <div class="info-about__description">
          <div class="title-description">
            <p class="title-description__title">Дата создания заявки:</p>
            <p class="title-description__text">
              {{ this.dateOfCreationMoment }}
            </p>
          </div>
          <div class="title-description">
            <p class="title-description__title">Последнее изменение:</p>
            <p class="title-description__text">{{ this.dateOfEditMoment }}</p>
          </div>
        </div>
      </div>
      <div class="competition__item-wrap">
        <div class="competition__item-btn">
          <button class="competition-btn competition-btn--view" @click="openModalApp">
            <img
                src="../../assets/svg/eyeWhite.svg"
                alt="eye"
                class="competition-btn__img"

            />
            Посмотреть
          </button>
          <button class="competition-btn competition-btn--menu"  @click="toggleDropdown">
            <img
                class="competition-btn__img"
                src="../../assets/svg/download.svg"
                alt="download"
            />
            <span class="competition-btn__tooltip">Скачать именную заявку</span>
          </button>
          <ul v-if="showDropdown" class="dropdown-menu">
            <li @click="downloadApplication('excel')">Скачать в Excel</li>
            <li @click="downloadApplication('word')">Скачать в Word</li>
<!--            <li @click="downloadApplication('pdf')">Скачать в PDF</li>-->
          </ul>
        </div>


        <!-- isEndRegistration -->
        <template v-if="!this.item_app.isFinished">
          <div
            class=""
            v-if="!this.item_app.isEndRegistration"
          >
            <router-link
              :to="{
                name: 'New-Application',
                params: { id: this.item_app.idCompetition },
              }"
            >
              <button class="button-green">Изменить</button>
            </router-link>
          </div>
          <div class="competition__item-btn" v-else>
            <router-link
              :to="{
                name: 'Edit-Application',
                params: { id: this.item_app.idCompetition },
              }"
            >
              <button class="button-green">Редактировать</button>
            </router-link>
          </div>
          <!-- <div
            class="competition__item-trash"
            v-if="!this.item_app.isEndRegistration"
          >
            <img
              src="../../assets/svg/trash.svg"
              alt="delete"
              @click="deleteApplication"
            />
          </div> -->
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import { actionTypes } from "@/store/modules/registrationComp";
import axios from 'axios'

export default {
  name: "AppItemApplication",
  data() {
    return {
      showDropdown: false,
    };
  },
  props: {
    item_app: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    dateOfEditMoment() {
      return this.$moment(this.item_app.editTime).locale("ru").format("LLL");
    },
    dateOfCreationMoment() {
      return this.$moment(this.item_app.createTime).locale("ru").format("LLL");
    },
  },
  methods: {
    //Cкачать список участников
    downloadListApp() {
      axios
          .get(`/final/user/application/${this.item_app.id}`, {
            responseType: "blob",
          })
          .then((response) => {
            const url = URL.createObjectURL(
                new Blob([response.data], {
                  type: "application/vnd.ms-excel",
                })
            );
            var fileName = `Список участников.xls`;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          });
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    handleOutsideClick(event) {
      const dropdownMenu = this.$el.querySelector(".dropdown-menu");
      const button = this.$el.querySelector(".competition-btn--menu");

      if (
          this.showDropdown &&
          !dropdownMenu.contains(event.target) &&
          !button.contains(event.target)
      ) {
        this.showDropdown = false;
      }
    },
    async downloadApplication(type) {
      const fileTypeMap = {
        excel: {
          endpoint: `/application/download/${this.item_app.id}/${type}`,
          mimeType: "application/vnd.ms-excel",
          fileName: "Именная заявка.xlsx",
        },
        word: {
          endpoint: `/application/download/${this.item_app.id}/${type}`,
          mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          fileName: "Именная заявка.docx",
        },
        // pdf: {
        //   endpoint: `/application/download-pdf/${this.item_app.id}`,
        //   mimeType: "application/pdf",
        //   fileName: "Именная заявка.pdf",
        // },
      };

      const fileConfig = fileTypeMap[type];
      if (!fileConfig) {
        alert("Invalid file type specified");
        return;
      }

      try {
        const response = await axios.get(fileConfig.endpoint, {
          responseType: "blob",
        });

        const url = window.URL.createObjectURL(
            new Blob([response.data], { type: fileConfig.mimeType })
        );

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileConfig.fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();

        window.URL.revokeObjectURL(url);
        this.showDropdown = false;
      } catch (error) {
        alert(`Error downloading document: ${error.message}`);
      }
    },
    // downloadApplication() {
    //   axios
    //       .get(`/application/download/${this.item_app.id}`, {
    //         responseType: "blob",
    //       })
    //       .then((response) => {
    //         const url = URL.createObjectURL(
    //             new Blob([response.data], {
    //               type: "application/vnd.ms-excel",
    //             })
    //         );
    //         var fileName = `Именная заявка.docx`;
    //         const link = document.createElement("a");
    //         link.href = url;
    //         link.setAttribute("download", fileName);
    //         document.body.appendChild(link);
    //         link.click();
    //       });
    // },
    //Удаление заявки
    // deleteApplication() {
    //   this.$store
    //     .dispatch(actionTypes.deleteCompetition, this.item_app.id)
    //     .then(() => {
    //       this.$store
    //         .dispatch(actionTypes.allUserCompetition)
    //         .then(() => {})
    //         .catch((er) => {
    //           console.log("Ошибка", er);
    //         });
    //     })
    //     .catch((er) => {
    //       console.log("Ошибка", er);
    //     });
    // },
    openModalApp() {
      this.$emit("openModalApp", this.item_app);
    },
    //  continueDraft() {
    //    this.$store
    //      .dispatch(actionTypes.getCompetitionItem, this.item_comp.id)
    //      .then(() => {})
    //      .catch((er) => {
    //        console.log("Ошибка", er);
    //      });
    //  },
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
};
</script>

<style>

</style>
