<template>
  <div class="form-group-block__item-row">

    <!-- Выбор даты -->
    <div class="custom-select-start">
      <div class="custom-select-start-box" @click="toggleDropdown">
        <label class="custom-select-start-label">День</label>
        <span class="custom-select-start-selected">{{ selectedDate || "Выберите дату" }}</span>
        <span class="custom-select-start-arrow" :class="{ open: isOpen }">
          <img src="../../assets/svg/ArrowDown.svg" alt="ArrowDown">
        </span>
      </div>
      <ul v-if="isOpen" class="custom-select-start-dropdown">
        <li
            v-for="(dateObj, index) in allDates"
            :key="index"
            @click="selectDate(dateObj)"
            :class="{ active: dateObj.date === selectedDate }"
        >
          {{ dateObj.date }}
        </li>
      </ul>
    </div>


    <!-- Выбор времени -->
    <div class="custom-select-start">
      <div class="custom-select-start-box">
        <label class="custom-select-start-label">Начало дня</label>
        <input
            v-model="selectedTime"
            @input="validateAndFormatTime"
            @blur="updateTime"
            placeholder="Введите время"
            class="custom-select-start-input custom-select-start-selected"
        />
      </div>
    </div>

  </div>
</template>

<script>
  import { actionTypesStartList } from "@/store/modules/createStartList";

  export default {
    name:"AppSelectCustom",

    data() {
      return {
        isOpen: false,
        selectedDate: null,
        selectedTime: "",
      };
    },
    props: {
      allDates: {
        type: Array,
        required: true,
        default: () => []
      }
    },
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      },
      getStartList() {
        this.$store
            .dispatch(actionTypesStartList.getStartListPerformance, {
              competitionId: this.$route.params.id,
              startDate: this.selectedDate,
            })
            .then(() => {})
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      selectDate(dateObj) {
        this.selectedDate = dateObj.date;
        this.selectedTime = dateObj.time; // Автозаполняем время

        this.isOpen = false;
        // Передаем в родителя
        this.$emit("update:selectedDate", this.selectedDate);
        this.$emit("update:selectedTime", this.selectedTime);
        // Загружаем старт-лист для выбранной даты
        this.$store
            .dispatch(actionTypesStartList.getStartListPerformance, {
              competitionId: this.$route.params.id,
              startDate: this.selectedDate,
            })
            .then(() => {
              this.getStartList()
            })
            .catch((er) => {
              console.log("Ошибка загрузки списка:", er);
            });
      },
      validateAndFormatTime() {
        let time = this.selectedTime.replace(/\D/g, ""); // Удаляем все нечисловые символы

        if (time.length > 4) time = time.slice(0, 4); // Ограничиваем 4 символами
        if (time.length >= 3) {
          time = time.slice(0, 2) + ":" + time.slice(2, 4); // Автоформатируем в XX:XX
        }

        // Проверяем корректность времени
        const [hours, minutes] = time.split(":");
        if (hours && parseInt(hours) > 23) time = "23:" + (minutes || "00");
        if (minutes && parseInt(minutes) > 59) time = (hours || "00") + ":59";

        this.selectedTime = time;
        // Передаем в родителя новое время
        this.$emit("update:selectedTime", this.selectedTime);
      },
      updateTime() {
        if (!this.selectedDate) return;

        // Отправляем запрос на изменение времени для выбранной даты
        this.$store
            .dispatch(actionTypesStartList.updateStartListTime, {
              competitionId: this.$route.params.id,
              selectedDate: this.selectedDate,
              newTime: this.selectedTime,
            })
            .then(() => {
              this.getStartList()
              console.log("Время успешно обновлено");
            })
            .catch((er) => {
              console.log("Ошибка обновления времени:", er);
            });
      },
      closeDropdown(event) {
        if (!this.$el.contains(event.target)) {
          this.isOpen = false;
        }
      }
    },
    mounted() {
      if (this.allDates.length > 0) {
        this.selectedDate = this.allDates[0].date;
        this.selectedTime = this.allDates[0].time;
        this.$emit("update:selectedDate", this.selectedDate);
        this.$emit("update:selectedTime", this.selectedTime);
        this.getStartList()
      }
      document.addEventListener("click", this.closeDropdown);
    },
    beforeDestroy() {
      document.removeEventListener("click", this.closeDropdown);
    }
  };
</script>
