<template>
  <div class="documents-wrap">
    <div class="documents__list">
      <div
          v-for="(doc) in competitionItemInfo.documents"
          :key="doc.id"
          class="document-item"
      >
        <div class="document-item-row__svg">
          <img v-if="doc.fileType === 'pdf'" src="../assets/svg/pdf-file.svg" alt="pdf icon">
          <img v-else-if="doc.fileType === 'doc' || doc.fileType === 'docx' " src="../assets/svg/doc-file.svg" alt="word icon">
          <img v-else-if="doc.fileType === 'pptx'" src="../assets/svg/pptx-file.svg" alt="pptx icon">
          <img v-else-if="doc.fileType === 'xls' || doc.fileType === 'xlsx' " src="../assets/svg/xlsx-file.svg" alt="xlsx icon">
          <!-- <span v-else>Loading...</span> -->
        </div>
        <div class="document-item-row__svg">
          <a :href="doc.url" target="_blank" class="document-item__link">
            {{ doc.fileName }}
          </a>
        </div>
        <span
            class="document-item__remove"
            @click="deleteDocument(doc.id)"
        >
            <img src="../assets/svg/cross.svg" alt="remove" />
          </span>
      </div>
      <!-- Показать текст, если документов нет -->
      <div v-if="competitionItemInfo.documents.length === 0" class="documents__placeholder">
        Документы отсутствуют
      </div>
    </div>
    <div class="documents" :style="{ background: color }">

      <!-- Загрузка новых документов -->
      <div class="documents__upload">
        <label class="documents__upload-label">
          <img src="../assets/svg/download-dark.svg" alt="upload" />
          <input
              type="file"
              id="files"
              ref="files"
              multiple
              @change="handleFileUploads"
          />
          <span>Добавить документы</span>
        </label>
        <div class="loader-overlay-mini" v-if="isLoadingDocuments">
          <div class="loader-spinner"></div>
        </div>
      </div>

      <!-- Отображение новых добавленных документов -->
      <div class="documents__list">
        <div
            v-for="(file, key) in files"
            :key="key"
            class="documents__file"
        >
          <span class="documents__file-name">{{ file.name }}</span>
          <span class="documents__remove" @click="removeFile(key)">
            <img src="../assets/svg/cross.svg" alt="remove" />
          </span>
        </div>
        <!-- Показать текст, если файлов нет -->
        <div v-if="files.length === 0" class="documents__placeholder">
          Нет добавленных документов*
        </div>
      </div>
      <button class="documents__submit" @click="submitFiles" :disabled="files.length === 0">Прикрепить документы</button>
    </div>


    <div class="validation" v-if="this.validationErrorsDoc !== null">
      <p class="validation__text">{{ validationErrorsDoc }}</p>
    </div>
  </div>
</template>

<script>
  import { actionTypes } from '@/store/modules/registrationComp';
  import { mapState } from 'vuex';

  export default {
    name: "AppAddDocuments",
    data() {
      return {
        files: [],
      };
    },
    props: {
      color: {
        type: String,
        default: "#f5f5f5",
      },
      idCompetition: {
        type: [String, Number],
        default() {
          return null;
        },
      },
    },
    computed: {
      ...mapState({
        validationErrorsDoc: (state) => state.registrationComp.validationErrorsDoc,
        competitionItemInfo: (state) => state.registrationComp.competitionItemInfo,
        isLoadingDocuments: (state) => state.registrationComp.isLoadingDocuments
      }),
    },
    methods: {
      async submitFiles() {
        const formData = new FormData();

        this.files.forEach((file) => {
          formData.append('files', file);
        });

        try {
          await this.$store.dispatch(actionTypes.saveDocuments, {
            files: formData,
            idCompetition: this.idCompetition,
          });
          console.log("Файлы успешно отправлены");
          this.files= [];
        } catch (error) {
          console.error("Ошибка при отправке файлов:", error);
        }
      },

      async deleteDocument(documentId) {
        try {
          await this.$store.dispatch(actionTypes.deleteDocument, documentId);

          const index = this.competitionItemInfo.documents.findIndex(
              (doc) => doc.id === documentId
          );
          if (index !== -1) {
            this.competitionItemInfo.documents.splice(index, 1);
          }

          console.log(`Документ с ID ${documentId} успешно удалён.`);
        } catch (error) {
          console.error("Ошибка при удалении документа:", error);
        }
      },

      removeFile(key) {
        this.files.splice(key, 1);
      },

      handleFileUploads() {
        const uploadedFiles = Array.from(this.$refs.files.files);
        this.files.push(...uploadedFiles);
      },
    },
  };
</script>

<style lang="scss">

</style>
