
<template>
  <div class="table-horizontal__column" >
    <div
        class="table-horizontal__cell table-horizontal__group"
        v-for="(type, idx) in item_sportsmen.performedTypes"
        :key="idx"
        draggable="true"
        @dragstart="dragStart(idx)"
        @dragover.prevent
        @drop="drop(idx)"
    >

      <div v-if="isEditing && type !== 0" class="type-edit__wrap">
        <div class="type-edit">
          <span class="type-edit__text">{{ type }}</span>
          <button @click="removeType(idx)">
            <img src="../../assets/svg/false-black.svg" alt="cross" class="type-edit__cross">
          </button>
        </div>
        <div class="drag-indicator">
          <img src="../../assets/svg/drag-indicator.svg" alt="">
        </div>
      </div>
      <div v-else-if="!isEditing && type !== 0" class="average highlighted">
        <span>{{ type }}</span>
      </div>
      <button v-if="type === 0" class="add-button" @click="addType">Добавить вид +</button>
<!--      <MultiSelectPerformedTypes performed-types=""/>-->
    </div>
  </div>
</template>

<script>
  // import MultiSelectPerformedTypes from '@/components/Startlist/ MultiSelectPerformedTypes.vue'

  export default {
    name: "CardPerformanceView",
    // components: { MultiSelectPerformedTypes },
    props: {
      item_sportsmen: Object,
      isEditing: Boolean,
    },
    data() {
      return {
        draggedIndex: null
      };
    },
    methods: {
      removeType(index) {
        this.$set(this.item_sportsmen.performedTypes, index, 0);
      },
      addType() {
        const newType = prompt("ФУНКЦИЯ НЕДОСТУПНА");
        if (newType) {
          const emptyIndex = this.item_sportsmen.performedTypes.indexOf(0);
          if (emptyIndex !== -1) {
            this.$set(this.item_sportsmen.performedTypes, emptyIndex, newType);
          } else {
            this.item_sportsmen.performedTypes.push(newType);
          }
        }
      },
      dragStart(index) {
        this.draggedIndex = index;
      },
      drop(index) {
        const draggedItem = this.item_sportsmen.performedTypes.splice(this.draggedIndex, 1)[0];
        this.item_sportsmen.performedTypes.splice(index, 0, draggedItem);
        this.draggedIndex = null;
      }
    }
  };
</script>

<style scoped>
.table-horizontal__cell {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  margin: 5px 0;
  position: relative;
  width: 100%;
}

.average {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.drag-indicator {
  cursor: grab;
  padding: 6px;
  text-align: center;
  font-size: 18px;
}
.drag-indicator img {
  width: 15px;
  height: 15px;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #d32f2f;
}

.add-button {
  font-size: 14px;
  border: 1px solid gray;
  color: gray;
  font-weight: 400;
  border-radius: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.2s, transform 0.1s, box-shadow 0.2s;
  padding: 10px 15px;
}
.add-button:hover {
  transform: scale(1.04);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.add-button:active {
  transform: scale(0.95);
}

.drag-handle {
  cursor: grab;
  font-size: 18px;
}

.type-edit {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-weight: bold;
}
.type-edit__wrap {
  background: #004EBC;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
}
.type-edit__text {
  color: white;
  font-size: 14px;
  display: block;
  font-weight: 500;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 6px;
}
.type-edit__cross {
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  top: -10px;
  right: 5px;
}
.type-edit__cross:hover {
  transform: scale(1.04);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.type-edit__cross:active {
  transform: scale(0.95);
}
</style>
