<template>
  <div class="custom-select-start margin-bottom20">
    <div class="custom-select-start-box" @click="toggleDropdown">
      <label class="custom-select-start-label">Выберите номинацию</label>
      <span class="custom-select-start-selected">{{ selectedCategoryText || "Выберите номинацию" }}</span>
      <span class="custom-select-start-arrow" :class="{ open: isOpen }">
        <img src="../../assets/svg/ArrowDown.svg" alt="ArrowDown">
      </span>
    </div>

    <ul v-if="isOpen" class="custom-select-start-dropdown">
      <!-- Заголовок "ИП" -->
      <li class="category-title">ИП</li>
      <li
          v-for="(item, index) in listNomination"
          :key="'ip-' + index"
          @click="selectCategory(item, 'individual')"
          :class="{ active: selectedCategory?.id === item.individualCategory.id && selectedCategory?.type === 'individual' }"
      >
        {{ item.individualCategory.individualCategory }} - {{ item.individualCategory.rank }}  - `Количество` - {{ item.count }}
      </li>

      <!-- Заголовок "ГУ" -->
      <li class="category-title">ГУ</li>
      <li
          v-for="(item, index) in listGroupNomination"
          :key="'gu-' + index"
          @click="selectCategory(item, 'group')"
          :class="{ active: selectedCategory?.id === item.groupCategory.id && selectedCategory?.type === 'group' }"
      >
        {{ item.groupCategory.type }} {{ item.groupCategory.individualCategory }} - {{ item.groupCategory.rank }}  - `Количество` - {{ item.count }}
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "NominationList",
    props: {
      listNomination: {
        type: Array,
        required: true,
        default: () => [],
      },
      listGroupNomination: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    data() {
      return {
        isOpen: false,
        selectedCategory: null, // { id: number, type: 'individual' | 'group' }
      };
    },
    computed: {
      selectedCategoryText() {
        if (!this.selectedCategory) return null;

        const { id, type } = this.selectedCategory;

        if (type === "individual") {
          const selectedItem = this.listNomination.find(
              (item) => item?.individualCategory?.id === id
          );
          return selectedItem
              ? `${selectedItem.individualCategory.individualCategory} - ${selectedItem.individualCategory.rank} - 'Количество' ${selectedItem.count}`
              : null;
        } else if (type === "group") {
          const selectedItem = this.listGroupNomination.find(
              (item) => item?.groupCategory?.id === id
          );
          return selectedItem
              ? `${selectedItem.groupCategory.type} ${selectedItem.groupCategory.individualCategory} - ${selectedItem.groupCategory.rank} - 'Количество' ${selectedItem.count}`
              : null;
        }

        return null;
      }
    },

    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      },
      selectCategory(item, type) {
        let categoryId = null;

        if (type === "individual" && item.individualCategory) {
          categoryId = item.individualCategory.id;
        } else if (type === "group" && item.groupCategory) {
          console.log('group bhbhbhbhb', item.groupCategory.id);
          categoryId = item.groupCategory.id;
        } else {
          console.error("Ошибка: у элемента нет корректного ID", item);
          return;
        }

        this.selectedCategory = { id: categoryId, type };
        this.isOpen = false;

        this.$emit("update:selectedNomination", {
          nominationId: categoryId,
          type,
        });
      },
      closeDropdown(event) {
        if (!this.$el.contains(event.target)) {
          this.isOpen = false;
        }
      },
    },
    mounted() {
      document.addEventListener("click", this.closeDropdown);
    },
    beforeDestroy() {
      document.removeEventListener("click", this.closeDropdown);
    },
  };
</script>

<style scoped>
.custom-select-start-dropdown {
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: 4px;
  padding: 0;
  list-style: none;
  max-height: 250px;
  overflow-y: auto;
  z-index: 10;
}

/* Заголовки категорий */
.category-title {
  padding: 8px;
  font-size: 12px;
  font-weight: bold;
  color: #2d0b00;
  background: #f0f0f0;
  cursor: default;
}

/* Опции */
.custom-select-start-dropdown li {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #2d0b00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Подсветка активной опции */
.custom-select-start-dropdown li:hover,
.custom-select-start-dropdown li.active {
  background: #f0f0f0;
  color: black;
}
</style>
