import { render, staticRenderFns } from "./UpDownTab.vue?vue&type=template&id=afaa3016&scoped=true&"
import script from "./UpDownTab.vue?vue&type=script&lang=js&"
export * from "./UpDownTab.vue?vue&type=script&lang=js&"
import style0 from "./UpDownTab.vue?vue&type=style&index=0&id=afaa3016&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afaa3016",
  null
  
)

export default component.exports