var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"competition__item"},[_c('div',{class:[
      this.item_comp.isFinished
        ? 'header__block-brown'
        : 'header__block-draft',
    ]},[(this.item_comp.isFinished)?_c('p',{staticClass:"header__text"},[_vm._v(" Соревнования #"+_vm._s(this.item_comp.id)+" ")]):_c('p',{staticClass:"header__text"},[_vm._v("Черновик #"+_vm._s(this.item_comp.id))])]),_c('div',{staticClass:"info-about draft"},[_c('div',{},[_c('div',{staticClass:"info-about__title"},[_c('p',{staticClass:"info-about__title-text"},[_vm._v(" "+_vm._s(this.item_comp.title)+" ")])]),_c('div',{staticClass:"info-about__description"},[_c('div',{staticClass:"title-description"},[_c('p',{staticClass:"title-description__title"},[_vm._v("Дата создания черновика:")]),_c('p',{staticClass:"title-description__text"},[_vm._v(" "+_vm._s(this.dateOfCreationMoment)+" ")])]),_c('div',{staticClass:"title-description"},[_c('p',{staticClass:"title-description__title"},[_vm._v("Последнее изменение:")]),_c('p',{staticClass:"title-description__text"},[_vm._v(_vm._s(this.dateOfEditMoment))])])])]),_c('div',{staticClass:"competition__item-wrap"},[(!this.item_comp.isFinished)?_c('div',{staticClass:"competition__item-btn-2"},[_c('router-link',{attrs:{"to":{
            name: 'Creation',
            params: { id: this.item_comp.id },
          }}},[_c('button',{staticClass:"mandated__tag orange",on:{"click":function($event){return _vm.continueDraft()}}},[_vm._v(" Продолжить ")])])],1):_c('div',{staticClass:"competition__item-btn-2"},[_c('router-link',{attrs:{"to":{
            name: 'Creation',
            params: { id: this.item_comp.id },
          }}},[_c('button',{staticClass:"mandated__tag dark",on:{"click":function($event){return _vm.continueDraft()}}},[_vm._v("Изменить")])])],1),_c('div',{staticClass:"competition__item-trash"},[_c('img',{attrs:{"src":require("../assets/svg/trash.svg"),"alt":"delete"},on:{"click":_vm.deleteDraft}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }