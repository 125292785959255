<template>
  <div class="content-card">
    <div class="content-card__subtitle">
      <h2 class="tag tag-open">Создание перерыва</h2>
    </div>
    <div class="form-group">
      <div class="custom-select-start">
        <div class="custom-select-start-box">
          <label class="custom-select-start-label">Время на перерыв в минутах</label>
          <input
              type="text"
              v-model="nameBreak"
              placeholder="Введите название перерыва"
              required
              class="custom-select-start-input"
          />
        </div>
      </div>
    </div>
    <div class="form-group__row">
      <div class="custom-select-start">
        <div class="custom-select-start-box">
          <label class="custom-select-start-label">Время на перерыв в минутах</label>
          <input
              v-model="timeBreak"
              type="number"
              placeholder="0"
              min="0"
              class="custom-select-start-input"
          />
        </div>
      </div>
      <div class="custom-select-start">
        <div class="custom-select-start-box">
          <label class="custom-select-start-label">На какое место потока его поставить</label>
          <input
              v-model="position"
              type="number"
              placeholder="0"
              min="0"
              class="custom-select-start-input"
          />
        </div>
      </div>
    </div>
    <div class="form-group-btn">
      <button class="mandated__tag active" @click="createBreak">Создать перерыв</button>
    </div>

  </div>
</template>
<script >
  import { actionTypesStartList } from '@/store/modules/createStartList'

  export default {
    components: {  },
    name:'AppCreateFlowBreak',
    data() {
      return {
        nameBreak: '',
        timeBreak: '',
        orderPerformance: '',
        position: '',

      };
    },
    props: {
      about_start_list: {
        type: Object,
      },
      selectedDate: {
        type: String,
      }
    },
    methods: {

      createBreak() {
        var breakData = {
          competitionId:   this.$route.params.id,
          startDate: this.selectedDate,
          name: this.nameBreak,
          duration: this.timeBreak,
          position: this.position,
        }
        this.$store
            .dispatch(actionTypesStartList.addBreakFlowInProtocol, breakData)
            .then((res) => {
                  breakData = {}
                  this.nameBreak =  '',
                  this.timeBreak= '',
                  this.orderPerformance= '',
                  this.position= '',
                      this.$store
                          .dispatch(actionTypesStartList.getStartListPerformance, {
                            competitionId: this.$route.params.id,
                            startDate: this.selectedDate,
                          })
                          .then(() => {})
                          .catch((er) => {
                            console.log("Ошибка", er);
                          });
              console.log(res)
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
    },

  }
</script>
