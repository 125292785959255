<template>
  <div class="table-horizontal__row">
    <div class="table-horizontal__column">
      <div class="table-horizontal__delete">
        <span class="table__icon-delete"  @click="deletePerformance()">🗑️</span>
      </div>
      <!-- Полоса для захвата и перетаскивания -->
      <div class="table-horizontal__cell drag-handle">
        &#9776; <!-- Символ полосок для захвата -->
      </div>

      <!-- Место спортсмена -->
      <div class="table-horizontal__cell table-horizontal__position">{{ item_sportsmen.position }}</div>

      <!-- ФИО и клуб спортсмена -->
      <div class="table-horizontal__cell table-horizontal__fio">
        <span class="fio">{{ item_sportsmen.surname }} {{ item_sportsmen.name }} {{ item_sportsmen.patronymic }}</span>
      </div>
      <div class="table-horizontal__cell table-horizontal__club">
        <span class="club">{{ item_sportsmen.region }} {{ item_sportsmen.club }}</span>
      </div>
    </div>
<!--    ВИДЫ СПОРТМЕНОВ-->
    <CardPerformanceView :item_sportsmen="item_sportsmen" :isEditing="isEditing" />
  </div>

</template>

<script>
  import { actionTypesStartList } from '@/store/modules/createStartList'
  import CardPerformanceView from '@/components/Startlist/CardPerformanceView.vue'

  export default {
    name: "ItemPerformance",
    components: { CardPerformanceView },
    props: {
      item_sportsmen: Object,
      item_flow: Object,
      isEditing:Boolean
    },
    data() {
      return {
      };
    },
    methods: {
      deletePerformance() {

        const joinData = {
          flowId: this.item_flow.flowId,
          sportsmenId: this.item_sportsmen.sportsmenId,
        };
        this.$store
            .dispatch(actionTypesStartList.deleteSportsman, joinData)
            .then(() => {
              this.getStartList()
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      //ПОЛУЧЕНИЕ ПОЛНОГО СТАРТОВОГО ЛИСТА
      getStartList() {
        this.$store
            .dispatch(actionTypesStartList.getStartListPerformance, {
              competitionId: this.$route.params.id,
              startDate: this.selectedDate,
            })
            .then(() => {})
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
    }
  };
</script>
