<template>
  <div class="table-horizontal modern-design">
    <!-- Заголовок потока с номером и временем -->
    <div class="table-horizontal__header">
      <div class="flow-header">
        <div class="flex-container-20 df">
        <h3 class="flow-number">
          Поток №
          <input v-if="isEditing" v-model.number="editedFlowNumber" type="number" class="flow-input" />
          <span v-else>{{ item_flow.flowNumber }}</span>
        </h3>
        <button @click="toggleEdit" class="edit-button">
          <i class="fas fa-pencil-alt"></i>
        </button>
        </div>
<!--        v-bind:class="classDisable"-->
        <UpDownTab @upFlow="upFlow" @downFlow="downFlow" :item_flow="item_flow"  class="flex-container-10" />
        <div class="time-card-performance flex-container-30" >
          <div class="time-card-performance__title">
        <span class="flow-time">Время потока: {{ item_flow.timeFrom }} - {{ item_flow.timeTo }}</span>
          </div>
          <div class="time-card-performance__time">
        <span class="time-card-performance__subtitle margin-right-20" v-if="item_flow.listType === 'FLOW'">Время одно выступление</span>
            <span class="time-card-performance__subtitle margin-right-20" v-else>Время перерыва в минутах</span>

        <input v-if="isEditing" v-model.number="editedPerformanceTime" type="number" class="time-input" />
        <span v-else class="time-card-performance__subtitle" > {{ item_flow.performanceTime }} {{ item_flow.listType === 'FLOW' ? 'сек.' : 'мин.' }}</span>
          </div>
        </div>
        <AppCardPerformanceSelectType :flowId="item_flow.flowId" :selectedDate="selectedDate"  class="flex-container-30"/>
        <CardPerformanceTab :item_flow="item_flow" :selectedDate="selectedDate"    v-model="isEditing" @save="saveChangeAllFlowSubmit" class="flex-container-10"  />
      </div>
    </div>
        <div class="table-horizontal__body-break">
         <p class="flow-time"> {{item_flow.title}}</p>
        </div>
    <!-- Draggable контейнер для спортсменов -->
    <draggable
        v-model="item_flow.sportsmen"
        group="sportsmen"
        handle=".drag-handle"
        @end="onDragEnd"
        class="draggable-container"
    >
      <ItemPerformance v-for="(sport, index) in item_flow.sportsmen" :key="index" :item_sportsmen="sport" :item_flow="item_flow" :isEditing="isEditing" />
    </draggable>
      <AppMergeAddAthlete  :item_flow="item_flow" :selectedDate="selectedDate" @mergeWithLower="joinFlowInStartListNext" />
  </div>
</template>

<script>
  import draggable from "vuedraggable"; // Импорт компонента для перетаскивания
  import { actionTypesStartList } from "@/store/modules/createStartList";
  import CardPerformanceTab from '@/components/Startlist/CardPerformanceTab.vue'
  import UpDownTab from '@/components/Startlist/UpDownTab.vue'
  import AppMergeAddAthlete from '@/components/Startlist/MergeAndAddTabs.vue'
  import ItemPerformance from '@/components/Startlist/ItemPerformance.vue'
  import AppCardPerformanceSelectType from '@/components/Startlist/CartPerformeceSelectType.vue'

  export default {
    name: "CardPerformance",
    data() {
      return {
        isChangeFlow: false,
        isEditing: false,
        editedFlowNumber: this.item_flow.flowNumber,
        editedPerformanceTime: this.item_flow.performanceTime,

      }
    } ,

    components: { AppCardPerformanceSelectType, ItemPerformance, AppMergeAddAthlete, UpDownTab, CardPerformanceTab, draggable }, // Регистрация компонента draggable
    props: {
      item_flow: {
        type: Object, // Ожидается объект с массивом спортсменов и временем потока
        required: true,
      },
      selectedDate: {
        type: String,
      },
      idx: {
        type: Number,
        required: true,
      },
      about_start_list: {
        type: Object,
      },
      allList: {
        type: Array,
      }
    },
    computed: {

      disable() {
        return  this.item_flow.flowNumber < 2
      }
    },
    methods: {
      toggleEdit() {
        this.isEditing = !this.isEditing;
        if (!this.isEditing) {
          this.saveChanges();
        }
      },
      saveChanges() {
        this.item_flow.flowNumber = this.editedFlowNumber;
        this.item_flow.performanceTime = this.editedPerformanceTime;
        // this.saveChangeAllFlowSubmit();
      },
        // Метод для изменения позиции потока или перерыва
        changeFlowPosition(direction) {
          const newPosition = direction === 'up' ? this.item_flow.flowNumber - 1 : this.item_flow.flowNumber + 1;

          // Определяем, какой тип позиции нужно изменить: поток или перерыв
          const actionType = this.item_flow.listType === 'BREAK'
              ? actionTypesStartList.changePositionBreak
              : actionTypesStartList.changePositionFlow;

          // Данные для dispatch в зависимости от типа
          const payload = this.item_flow.listType === 'BREAK'
              ? { breakId: this.item_flow.breakId, newPosition }
              : { flowId: this.item_flow.flowId, newPosition };

          // Вызов dispatch с соответствующим экшеном и данными
          this.$store
              .dispatch(actionType, payload)
              .then(() => {
                this.getStartList()
                console.log(`Позиция ${this.item_flow.listType === 'BREAK' ? 'перерыва' : 'потока'} успешно перемещена ${direction}`);
              })
              .catch((er) => {
                console.error(`Ошибка при изменении позиции ${this.item_flow.listType === 'BREAK' ? 'перерыва' : 'потока'}:`, er);
              });
        },
      updatePositionsSportsmen() {
        this.item_flow.sportsmen.forEach((sport, index) => {
          sport.position = index + 1; // Проставляем новую позицию начиная с 1
        });

      },
      deletePerformance(sport) {
        console.log('sport',sport);
        const joinData = {
          flowId: this.item_flow.flowId,
          sportsmenId: sport.sportsmenId,
        };
        this.$store
            .dispatch(actionTypesStartList.deleteSportsman, joinData)
            .then(() => {
              // this.item_flow.sportsmen = this.item_flow.sportsmen.filter(s => s.sportsmenId !== sport.sportsmenId);
              // this.updatePositionsSportsmen()
              this.getStartList()
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
        // Метод для поднятия потока или перерыва
        upFlow() {
          if(!this.disable) {
            this.changeFlowPosition('up');
          } else console.log('не прошел')
        },

        // Метод для опускания потока или перерыва
        downFlow() {
          this.changeFlowPosition('down');

      },
      joinFlowInStartListNext() {
        const nextFlowNumber = this.item_flow.flowNumber + 1;
        const nextFlow = this.allList.find(flow => flow.flowNumber === nextFlowNumber);

        const joinData = {
          flowFirstId: this.item_flow.flowId,
          flowSecondId: nextFlow ? nextFlow.flowId : null, // Находим flowId следующего потока
          competitionId: this.$route.params.id,
          startDateAndTime: this.about_start_list.startDateAndTime,
        };

        // Проверка, если nextFlow не найден
        if (!nextFlow) {
          console.error('Следующий поток с указанным flowNumber не найден');
        }
        this.$store
            .dispatch(actionTypesStartList.joinFlowInStartList, joinData)
            .then(() => {
              this.getStartList()

            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      saveChangeAllFlowSubmit() {
        this.$store
            .dispatch(actionTypesStartList.saveChangeAllFlowSubmit, this.item_flow)
            .then(() => {
              this.getStartList()
              this.isChangeFlow = false
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      //
      getStartList() {
        this.$store
            .dispatch(actionTypesStartList.getStartListPerformance, {
              competitionId: this.$route.params.id,
              startDate: this.selectedDate,
            })
            .then(() => {})
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      deleteBreak() {
        this.$store
            .dispatch(actionTypesStartList.deleteBreakFlowProtocol, {
              breakId: this.item_flow.breakId
            })
            .then(() => {
              this.getStartList()
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      // Удаление потока
      deleteFlow() {
        this.$store
            .dispatch(actionTypesStartList.deleteFlowProtocol, {
              flowId: this.item_flow.flowId
            })
            .then(() => {
              this.getStartList()
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },

      deleteSubmit() {
        if(this.item_flow.listType === 'FLOW') {
        this.deleteFlow()
        } else {
        this.deleteBreak()
        }
      },
      // Обновление позиций после перетаскивания
      onDragEnd(evt) {
        this.isChangeFlow = true
        console.log("Перетаскивание завершено:", evt);
        this.updatePositions();
      },
      // Обновление позиций спортсменов
      updatePositions() {
        this.item_flow.sportsmen.forEach((sport, index) => {
          sport.position = index + 1; // Обновление позиции в массиве
        });
      },
    },
  };
</script>

<style scoped>
.modern-design {
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.flow-number-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.flow-number {
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.flow-input,
.time-input {
  width: 100px;
  padding: 5px 20px;
  border: 1px solid #ccc;
  border-radius: 6px;
  text-align: center;
  margin-left: 20px;
}
.edit-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #007bff;
  transition: color 0.3s;
}
.edit-button:hover {
  color: #0056b3;
}

.arrow-button {
  cursor: pointer;
  border: 1px solid #ccc;
  background: white;
  padding: 8px 12px;
  border-radius: 6px;
  transition: background 0.3s, transform 0.2s;
}
.arrow-button:hover {
  background: #f0f0f0;
}
.arrow-button:active {
  transform: scale(0.95);
}
.delete-button {
  background: #d9534f;
  color: white;
  padding: 10px 18px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s;
}
.delete-button:hover {
  background: #c9302c;
}
.save-button {
  background: #5cb85c;
  color: white;
  padding: 10px 18px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s;
}
.save-button:hover {
  background: #4cae4c;
}
.time-card-performance__subtitle {

    display: block;
    font-weight: 500;
    font-size: 12px;
       color: #2d0b00;
}

.time-card-performance__time {
  align-items: center;
  display: flex;
  justify-content: start;
}
.flex-container-10 {
  flex: 10%;
}
.flex-container-20 {
  flex: 20%;
}
.flex-container-60 {
  flex: 60%;
}

.flex-container-30 {
  flex: 30%;
}
</style>
