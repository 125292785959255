<template>
  <div class="loader-container">
    <div class="loader-overlay-mini" v-if="isLoading">
      <div class="loader-spinner"></div>
    </div>
  <!-- Отображение собранных заявок -->
  <div class="search">
    <item-app-group
      v-for="(item, index) in this.aboutRegistrApp.groups"
      :key="index"
      @deleteItemAppGroup="deleteItemAppGroup"
      :item_sport="item"
      :index="index"
    />
<!--    <div class="validation" v-if="this.validationErrorsAddSportsmen !== null">-->
<!--      <p class="validation__text">{{ validationErrorsAddSportsmen.message }}</p>-->
<!--    </div>-->
    <!-- Создание собранных заявок -->
    <div
      class="search__wrap-block-select"
      v-if="this.aboutRegistrApp.status !== 'CLOSE_EDIT_APPLICATION' && this.aboutRegistrApp.status !== 'FINISH'"
    >
      <div class="search__select-categ">
        <select-with-animate-categ
          @input="safeGroupCateg"
          :default="this.defaultCategory"
          :options="this.groupCategories"
          v-if="this.groupCategories !== null"
          ref="categSelect"
        />
      </div>
      <div class="search__add-sportsmen" v-if="safeGroupCategories !== '' && safeGroupCategories !== {}">
        <app-add-group-select  @showModalAddGroup="showModalAddGroup"
                               :selectGroupCateg="this.safeGroupCategories"
                               @input="safeGroupPeople"
                               @openModal="openModal('isShowAddGroup')"
                               :default="this.defaultGroupSporsmen"
                               :options="this.allGroups"  :windowWidth="isMobile"/>
      </div>
      <div class="search__add-sportsmen-text" v-else>
        <p class="tag tag-open">Для добавления группы выберите категорию</p>
      </div>

    </div>
    <div class="search__wrap-block-block" v-else>
      <div class="search__wrap-block padding10" v-if="this.aboutRegistrApp.groups === null">
        <p class="selected-text">Добавленных групп нет.</p>
      </div>
      <div class="competition-status">
        <p class="tag tag-finish">Добаление групп завершено!</p>
      </div>
    </div>
    <div class="button-add__title-with-svg" v-if="safeGroupCategories && safeGroupCategories.id"  @click="openModal('isShowAddGroup')">
      <div class="button-add__title">
        <p class="button-add__title-text">Добавить новую группу в категорию</p>
      </div>

      <div class="button-add__wrap-svg">
        <img src="../../assets/svg/plus.svg" alt="plus" />
      </div>
    </div>
    <div class="validation" v-if="this.validationErrorsAddGroup !== null">
      <p class="validation__text">{{ validationErrorsAddGroup }}</p>
    </div>
    <div class="about-application" v-if="this.aboutRegistrApp.groups">
      <p class="about-application__text">
        Итого в заявке:
        {{ this.aboutRegistrApp.groups.length }}
        групп
      </p>
    </div>
    <app-modal-add-group  v-if="isShowAddGroup"
                          :application_category="safeGroupCategories"
                          @close="closeModal('isShowAddGroup')"
                          @addCoach="openModal('isShowAddCoach')"
                          @editSportsmen="editItem('item_sportsmen', 'isShowModifSportsmen', $event)"
                          @addSportsmen="openModal('isShowAddSportsmen')"/>
    <app-modal-add-sportsmen  v-if="isShowAddSportsmen"
                              @close="closeModal('isShowAddSportsmen')"
                              @addCoach="openModal('isShowAddCoach')"/>
    <modal-add-coach
        v-if="isShowAddCoach"
        @close="closeModal('isShowAddCoach')"
    />
    <modal-modification-sportsmen
        v-if="isShowModifSportsmen"
        @addCoach="openModal('isShowAddCoach')"
        @close="closeModal('isShowModifSportsmen')"
        :item_sportsmen="item_sportsmen"
    />
    <!-- Создание собранных заявок -->
  </div>
  </div>
</template>

<script>
import { actionTypes } from "@/store/modules/registration";
import { actionTypesEntered } from "@/store/modules/enteredData";
import { mapState } from "vuex";
import SelectWithAnimateCateg from "../customSelect/SelectWithAnimateCateg.vue";
// import CustomSelectAppGroup from "../customSelect/CustomSelectAppGroup.vue";
import ItemAppGroup from "./ItemAppGroup.vue";
import AppAddGroupSelect from '@/components/customSelect/AddGroupSelect.vue'
import AppModalAddGroup from '@/components/Modal/ModalAddGroup.vue'
import AppModalAddSportsmen from '@/components/Modal/ModalAddSportsmen.vue'
import ModalAddCoach from '@/components/Modal/ModalAddCoach.vue'
import ModalModificationSportsmen from '@/components/Modal/ModalModificationSportsmen.vue'
// import AppAddPeopleSelect from '@/components/customSelect/AddPeopleSelect.vue'
// import AppAddPeopleSelect from '@/components/customSelect/AddPeopleSelect.vue'
export default {
  name: "AppGroupApplication",
  data() {
    return {
      defaultCategory: "Категория группы",
      defaultGroupSporsmen: "Выберите группу",
      safeGroupCategories: "",
      listAppGroup: [],
      dataIdToIndSportsmen: {},
      windowWidth: window.innerWidth, // Инициализируем начальное значение
      isShowAddGroup: false,
      isShowAddCoach: false,
      isShowAddSportsmen: false,
      isShowModifSportsmen:false,
      item_sportsmen: null,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      individualCategories: (state) => state.registration.individualCategories,
      individualSportsmens: (state) => state.registration.individualSportsmens,
      groupCategories: (state) => state.registration.groupCategories,
      allGroups: (state) => state.enteredData.allGroups,
      aboutRegistrApp: (state) => state.registration.aboutRegistrApp,
      validationErrorsAddGroup: (state) => state.registration.validationErrorsAddGroup,
    }),
    isMobile() {
      return this.windowWidth <= 1024; // Логика определения мобильного режима
    },
  },
  components: {
    // AppAddPeopleSelect,
    ModalModificationSportsmen,
    ModalAddCoach,
    AppModalAddSportsmen,
    AppModalAddGroup,
    // AppAddPeopleSelect,
    AppAddGroupSelect,
    SelectWithAnimateCateg,
    // CustomSelectAppGroup,
    ItemAppGroup,
  },
  methods: {
    closeModal(modalProperty) {
      this[modalProperty] = false;
      // Проверяем, нужно ли оставить скролл заблокированным (если есть другие открытые модальные окна)
      if (!this.anyModalOpen()) {
        document.body.classList.remove('no-scroll');
      }
      this.scrollToEndOfPage()
    },
    anyModalOpen() {
      // Проверяем, открыто ли еще какое-либо модальное окно
      return this.isShowAddSportsmen || this.isShowAddCoach ||
         this.isShowAddGroup || this.isShowModifSportsmen;
    },
    openModal(modalProperty) {
      this[modalProperty] = true;
      document.body.classList.add('no-scroll');
    },
    editItem(itemName, modalName, item) {
      this[itemName] = item;
      this.openModal(modalName);
    },
    scrollToEndOfPage() {
      // Прокрутка страницы вниз до конца
      window.scrollTo({
        top: document.body.scrollHeight, // Прокрутить до конца страницы
        behavior: 'smooth', // Плавная прокрутка
      });
    },

    deleteItemAppGroup(item) {
      this.aboutRegistrApp.sportsmans.splice(
        0,
        this.aboutRegistrApp.sportsmans.length,
        ...this.aboutRegistrApp.sportsmans.filter(
          (n) => n.idApplication !== item.idApplication
        )
      );
      this.$store
        .dispatch(actionTypes.deleteGroupApp, {
          idCompetition: this.$route.params.id,
          idApplication: item.applicationId,
        })
        .then(() => {})
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    showModalAddGroup() {
      this.$emit("showModalAddGroup");
    },
    safeGroupPeople(item) {
      this.$store
        .dispatch(actionTypes.addGroupApp, {
          idCompetition: this.$route.params.id,
          idGroup: item.groups,
          idCategory: item.categ.id,
        })
        .then(() => {
          this.safeGroupCategories = {}
        })
        .catch((er) => {
          console.log(er, "Error");
        });
      this.$refs.categSelect.updateData();
    },
    safeGroupCateg(item) {
      this.safeGroupCategories = item;
    },
  },
  mounted() {
    // Получение всех групп
    this.$store
      .dispatch(actionTypesEntered.getGroups)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
  },
};
</script>

<style></style>
