<template>
  <div class="preview-block__loader">
    <div class="lds-spinner" v-if="isLoadingPhoto">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>

    <div class="preview-block">
      <img
        v-if="isAddfoto === false && this.competitionItemInfo.urlImage !== ''"
        class="preview-block__img"
        :src="`${dogImage}`"
        alt="foto"
      />
      <img
        v-if="
          isAddfoto === false &&
          isUpdatePhoto === true &&
          this.competitionItemInfo.urlImage == ''
        "
        class="preview-block__img"
        :src="`${dogImage}`"
        alt="foto"
      />
      <div
        class="preview-block__empty-poster"
        v-if="
          isAddfoto === false &&
          isUpdatePhoto === false &&
          this.competitionItemInfo.urlImage === ''
        "
      >
        <img src="../assets/svg/logoGray.svg" alt="" />
      </div>
    </div>
    <!-- v-if="isAddfoto === true && this.newPhoto.length -->
    <div v-if="isAddfoto === true">
      <div class="client-block__img" id="foto">
        <div
          class="photo-item__column"
          v-for="(file, index) in this.newPhoto"
          :key="index"
        >
          <div class="preview-image">
            <div
              class="preview-remove"
              :data-name="file.file.name"
              @click="removeHandler(index, file.file.name)"
            >
              <img src="../assets/svg/cross.svg" alt="cross" />
            </div>
            <div class="preview-block">
              <img :src="file.src" alt="foto" />
            </div>
            <div class="preview-upload">
              <button
                type="submit"
                class="preview-upload__btn"
                v-if="isAddfoto === true"
                @click="putImage()"
              >
                Загрузить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ValidationErrors
      v-if="validationErrors"
      :validation-errors="validationErrors"
    />
    <div class="example-2" v-if="isAddfoto === false">
      <div class="form-group">
        <form ref="myForm">
          <input
            type="file"
            id="file"
            ref="file"
            accept="image/jpeg"
            @change="handleFileUpload"
            class="input-file"
          />
          <label for="file" class="btn btn-tertiary-new js-labelFileNew">
            <img
              src="../assets/svg/gallery-add.svg"
              alt="download"
              class="btn-img"
            />
            <span class="js-fileName">Добавить постер</span>
          </label>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { actionTypes } from "@/store/modules/registrationComp";
import ValidationErrors from "./ValidationErrors.vue";
export default {
  components: { ValidationErrors },
  name: "AppAddPoster",
  data() {
    return {
      isAddfoto: false,
      isUpdatePhoto: false,
      newPhoto: [],
      changePhoto: [],
      file: [],
    };
  },
  props: {
    id: {
      type: [String, Number],
      default() {
        return {};
      },
    },

    competitionItemInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState({
      validationErrors: (state) => state.registrationComp.validationErrors,
      isLoadingPhoto: (state) => state.registrationComp.isLoadingPhoto,
      // competitionItemInfo: (state) =>
      //   state.registrationComp.competitionItemInfo,
    }),
    dogImage() {
      if (!this.isUpdatePhoto && this.competitionItemInfo.urlImage !== "") {
        return this.competitionItemInfo.urlImage;
      } else {
        return this.changePhoto[0].src;
      }
    },
  },
  methods: {
    show() {
      this.showModal = true;
    },
    handleFileUpload(event) {
      console.log(event);
      this.file = this.$refs.file.files;
      [...this.file].forEach((child) => {
        if (!child.type.match("image")) {
          return console.log("Не изображение!");
        }
        const reader = new FileReader(); //создали ридер
        reader.onload = (ev) => {
          this.isAddfoto = true;
          this.isUpdatePhoto = true;
          this.newPhoto.push({
            src: ev.target.result,
            file: this.file,
          });
        };
        reader.readAsDataURL(child); //считывание файла(ассинхронное)
      });
    },
    putImage() {
      let formData = new FormData();
      let file = this.file;
      formData.append("file", file[0]);
      const dataWithId = {
        formData: formData,
        id: this.id,
      };
      // formData.append("id", this.client_item.id);
      this.$store
        .dispatch(actionTypes.addPhoto, dataWithId)
        .then((resp) => {
          console.log(resp, "resp");
          this.isAddfoto = false;
          this.changePhoto = this.newPhoto;
          this.newPhoto = [];
        })
        .catch((er) => {
          console.log(er, "Error");
        });
    },

    removeHandler(index, name) {
      console.log(name);
      this.newPhoto.splice(index, 1);
      this.isAddfoto = !this.isAddfoto;
      this.isUpdatePhoto = !this.isUpdatePhoto;
    },
  },
};
</script>

<style></style>
