<template>
  <div class="tabs-container">
    <div class="tabs">
      <div
          v-for="(tab, index) in tabs"
          :key="index"
          class="tab"
          :class="{
          active: selectedTab === tab.value,
          disabled: tab.value === 'withViewSpinnerCategory'
        }"
          @click="selectTab(tab.value)"
      >
<!--        v-if="tab.value !== 'break'"-->
        <span>{{ tab.label }}</span>
<!--        <div v-if="isLoading(tab.value)" class="spinner"></div>-->

        <!-- Градиент-свечение -->
<!--        <div v-if="selectedTab === tab.value" class="active-glow"></div>-->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CustomTabs",
    data() {
      return {
        selectedTab: "noSpinner",
        tabs: [
          { label: "Без вертушки", value: "noSpinner" },
          { label: "С вертушкой по видам", value: "withViewSpinner" },
          { label: "С вертушкой по категориям", value: "withViewSpinnerCategory" },
          { label: "Вертушка одного вида", value: "withViewSpinnerOneType" },
          { label: "Перерыв", value: "break" }
        ]
      };
    },
    methods: {
      selectTab(value) {
        if (value !== "withViewSpinnerCategory") {
          this.selectedTab = value;
          this.$emit("tabChange", value);
        }
      },
      isLoading(value) {
        return value === "withViewSpinner" || value === "withViewSpinnerCategory";
      }
    }
  };
</script>

<style scoped>
/* Контейнер вкладок */
.tabs-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Вкладки */
.tabs {
  display: flex;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  min-height: 50px; /* Фиксированная высота */
}

/* Одна вкладка */
.tab {
  padding: 15px 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  flex: 1;
  position: relative;
  transition: background 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 3px solid transparent;
}

/* Синий бар под активным табом */
.tab.active {
  border-bottom: 3px solid #e0e0e0;
}

/* Градиент-свечение наверху */
.tab.active .active-glow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(180deg, rgba(0, 123, 255, 0.6), transparent);
}

/* Перерыв (неактивный таб) */
.tab.disabled {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}

/* Спиннер (анимация загрузки) */
.spinner {
  width: 14px;
  height: 14px;
  border: 2px solid transparent;
  border-top: 2px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin-left: 5px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
