<template>
<div class="content-card">
<div class="content-card__subtitle">
  <h2 class="tag tag-open">Без вертушки</h2>
</div>
<div class="category-select-container">
  <div>
    <NominationSelector
        :listNomination="listNomination"
        :listGroupNomination="listGroupNomination"
        @update:selectedNomination="handleNominationChange"
    />
    <!-- Селектор видов (если есть виды) -->
    <MultiSelectPerformedTypes
        v-if="selectedNominationPerformedTypes.length"
        :performedTypes="selectedNominationPerformedTypes"
        @update:selectedTypes="handleSelectedTypes"
    />
  </div>
</div>
  <AppValidationErrors v-if="validationErrors && validationErrors.message" :validation-errors="validationErrors.message"/>

  <TimeHint :timeInputs="timeHints" />

<!-- Поле ввода времени на 1 выступление -->
<div class="form-group__row">

  <div class="form-group__row-item">

    <div class="custom-select-start">
      <div class="custom-select-start-box">
        <label class="custom-select-start-label">Время на 1 выступление (секунды)</label>
        <input
            v-model="timePerPerformance"
            type="number"
            placeholder="0"
            min="0"
            class="custom-select-start-input"
        />
      </div>
    </div>
  </div>

  <!-- Поле для ввода диапазона количества спортсменов в потоке -->
<!--  <div class="form-group__row-item">-->
    <div class="custom-select-start">
      <div class="custom-select-start-box">
        <label class="custom-select-start-label">Количество выступающих в потоке</label>
        <input
            id="minNumberOfAthletes"
            type="number"
            placeholder="1"
            v-model.number="countPerformance"
            min="1"
            max="100"
            class="custom-select-start-input"
        />
      </div>
    </div>
    <div class="custom-select-start">
      <div class="custom-select-start-box">
        <label class="custom-select-start-label">Место в потоке</label>
        <input
            id="minNumberOfAthletes"
            type="number"
            placeholder="1"
            v-model.number="orderFlow"
            min="1"
            max="100"
            class="custom-select-start-input"
        />
      </div>
    </div>
<!--  </div>-->
</div>
  <div class="form-group__row-item" v-if="countFreePerformance">
    <label for="" class="">Кол-во выст. оставшихся человек в категории</label>
    <p class="tag tag-open">{{countFreePerformance}}</p>
  </div>
<div class="form-group-btn">
  <!-- Кнопка создания потока -->
  <button class="mandated__tag active" @click="createStreamNoSpinner">Создать новый поток</button>
</div>
</div>
</template>
<script >

import TimeHint from '@/components/TimeHint.vue'
import { mapState } from 'vuex'
import NominationSelector from '@/components/Startlist/NominationSelector.vue'
import MultiSelectPerformedTypes from '@/components/Startlist/ MultiSelectPerformedTypes.vue'
import { actionTypesStartList } from '@/store/modules/createStartList'
import AppValidationErrors from '@/components/ValidationErrors.vue'

export default {
  components: { AppValidationErrors, MultiSelectPerformedTypes, NominationSelector, TimeHint },
  name:'AppCreateFlowNoSpinner',
  data() {
    return {
      selectedCategoryFull: null,
      selectedViews: [],
      timePerPerformance: '',
      countPerformance: '',
      orderPerformance: '',
      timeHints: ["1", "1.30", "1.45", "2", "3"], // Изначальные значения подсказок
      orderFlow: '',
      selectedNomination: null,
      selectedNominationType: null, // Тип номинации (ИП или ГУ)
      selectedTypes: [], // Выбранные виды
      selectedNominationPerformedTypes: [] // Виды для выбранной номинации
    };
  },
  computed: {
    ...mapState({
      listNomination: (state) => state.createStartList.listNomination,
      listGroupNomination: (state) => state.createStartList.listGroupNomination,
      validationErrors: (state) => state.createStartList.validationErrors,
      countFreePerformance: (state) => state.createStartList.countFreePerformance,
    }),

  },
  props: {
    about_start_list: {
      type: Object,
    },
    selectedDate: {
      type: String,
    }
  },
  methods: {
    createStreamNoSpinner() {
      // this.orderPerformance = 'withViewSpinner'
      this.orderPerformance = 'noSpinner'
      const performanceTypes = this.selectedTypes.map(item => item.id);
      console.log('Создание потока без вертушки', this.selectedTypes);
      var createFlow = {
        sportsmenCount: this.countPerformance,
        competitionId:   this.$route.params.id,
        categoryId: this.selectedNomination,
        performanceTime: this.timePerPerformance,
        performanceTypes: performanceTypes,
        protocolLocationType:  this.orderPerformance,
        roundingTime: true,
        typesPrograms: this.isGroupNomination,
        startDate: this.selectedDate,
        flowPosition: this.orderFlow,
      }
      console.log(createFlow)
      this.$store
          .dispatch(actionTypesStartList.addFlowInProtocol, createFlow)
          .then(() => {
            createFlow = {}
            this.$store
                .dispatch(actionTypesStartList.getStartListPerformance, {
                  competitionId: this.$route.params.id,
                  startDate: this.selectedDate,
                })
                .then(() => {})
                .catch((er) => {
                  console.log("Ошибка", er);
                });
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      console.log('Создание Перерыва', this.selectedView1,this.selectedView2);
    },
    handleCategoryChange() {
      this.selectedViews = [];
      this.selectedView1 = '';
      this.selectedView2 = '';
    },
    handleNominationChange({ nominationId, type }) {
      console.log(nominationId, type, "selectedNomination ID и Тип");

      this.selectedNomination = nominationId;
      this.selectedNominationType = type;

      let selectedNominationObj = null;

      // Проверяем, где искать номинацию
      if (type === "individual") {
        selectedNominationObj = this.listNomination.find(
            (nom) => nom.individualCategory.id === nominationId
        );
      } else if (type === "group") {
        selectedNominationObj = this.listGroupNomination.find(
            (nom) => nom.groupCategory.id === nominationId
        );
      }

      if (selectedNominationObj) {
        this.selectedNominationPerformedTypes =
            selectedNominationObj.performedTypes || [];
      } else {
        this.selectedNominationPerformedTypes = [];
      }

      console.log(
          this.selectedNominationPerformedTypes,
          "Виды для выбранной номинации"
      );
    },
    handleSelectedTypes(types) {
      this.selectedTypes = types;
      console.log(this.selectedTypes, "Выбранные виды");
    },
  },

}
</script>
