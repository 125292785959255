<template>
  <div class="mandated-info__block">
    <div class="search" style="flex-direction: row !important" >
      <div class="search__input">
        <img
            src="../../assets/svg/Search.svg"
            alt=""
            class="inputs-search__img"
        />
        <input
            type="text"
            class="inputs-search"
            placeholder="Общий поиск по фамилии и имени во всех категориях ИП"
            v-model="searchQuery"
        />
      </div>
    </div>
    <div class="mandated-info__wrap">
    <div class="mandated-info__label">
      <p class="label-text-profile">
        Для просмотра спортсменов подавших заявки выберите категорию
      </p>
    </div>
    <div class="mandated-info__select">
      <AppCategorySelect :all-category="listNominationInd" @categorySelected="getSportsmen"  :default="defaultCategory"/>
    </div>

    </div>

    <div
      class="mandated-info__table"
      v-if="(this.allSportsmens && this.allSportsmens.length !== 0)"
    >
      <div class="" v-if=" (safeIndividualCategories && safeIndividualCategories.id)">
      <div class="df">
        <p class="mandated__item-block-text-active"> Итого спортсменов в категориии: {{this.allSportsmens.length }}</p>
      </div>
      <div class="mandated-info__title-with-svg" @click="openModal('isShowAddSportsmen')">
        <div class="mandated-info__title">
          <p class="mandated-info__title-text">Добавить спортсмена в категорию</p>
        </div>

        <div class="mandated-info__wrap-svg" >
          <img src="../../assets/svg/plus.svg" alt="plus" />
        </div>
      </div>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th class="empty_th"></th>
            <th class="th__name" style="width: 1%">№</th>
            <th class="th__name" style="width: 1%">СП</th>
            <th class="th__name" style="width: 10%">Ф.И.О.</th>
            <th class="th__years" style="width: 5%">Год р.</th>
            <th class="th__section" style="width: 10%">Разряд</th>
            <th class="th__section" style="width: 15%">Клуб/Организация</th>
            <th class="th__section" style="width: 15%">Тренеры</th>
            <th class="th__section" style="width: 25%">Категория </th>
            <th class="th__section" style="width: 20%">Статус</th>

          </tr>
        </thead>
        <AppItemSportsmanInMandated v-for="(application, i) in this.allSportsmens" :idx="i" :listNominationInd="listNominationInd" @editSportsmen="editSportsmen" :safeIndividualCategories="safeIndividualCategories" :item_sportsmen="application.sportsmen" :item_categ="application.categ" :key="application.sportsmen.id"/>
      </table>
    </div>
    <div
      class="mandated-info__table"
      v-if="(this.allSportsmens && this.allSportsmens.length === 0) &&  (safeIndividualCategories && safeIndividualCategories.id)"
    >
      <div class="mandated-info__title-with-svg">
        <div class="mandated-info__title">
          <p class="mandated-info__title-text">Добавить спортсмена в категорию</p>
        </div>

        <div class="mandated-info__wrap-svg" @click="openModal('isShowAddSportsmen')">
          <img src="../../assets/svg/plus.svg" alt="plus" />
        </div>
      </div>
      <div class="no-data-text-wrap">
        <p class="no-data-text">
          В данной категории нет заявленных спортсменов*
        </p>
      </div>

    </div>
<!--    <div class="" v-if="this.allSportsmens && this.allSportsmens.length === 0">-->
<!--      <div class="mandated-info__block">-->
<!--        <p class="mandated__item-block-text-active">-->
<!--        Ничего не найдено!-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->
    <modal-add-sportsmen
        v-if="isShowAddSportsmen"
        :category="safeIndividualCategories"
        @close="closeModal('isShowAddSportsmen')"
        @addCoach="openModal('isShowAddCoach')"
    />
    <modal-add-coach
        v-if="isShowAddCoach"
        @close="closeModal('isShowAddCoach')"
    />
    <app-modal-edit-sportsman-performance v-if="isShowEditSportsman" :item_sportsmen="safeItemSportsman"  @close="closeModal('isShowEditSportsman')"
    />
  </div>

</template>

<script>
import { actionTypesMandated } from "@/store/modules/mandated";
import { mapState } from "vuex";
import ModalAddSportsmen from '@/components/Modal/ModalAddSportsmen.vue'
import ModalAddCoach from '@/components/Modal/ModalAddCoach.vue'
// import AppCategorySelect from '@/components/customSelect/CategorySelect.vue'
import AppItemSportsmanInMandated from '@/components/Mandated/ItemSportsmanInMandated.vue'
import AppModalEditSportsmanPerformance from '@/components/Modal/ModalEditSportsmanPerformance.vue'
import AppCategorySelect from '@/components/customSelect/CategorySelect.vue'

export default {
  components: { AppCategorySelect, AppModalEditSportsmanPerformance, AppItemSportsmanInMandated,  ModalAddCoach, ModalAddSportsmen },
  name: "AppInfoAboutSportsmens",
  data() {
    return {
      defaultCategory: "Выберите категорию",
      isShowAddSportsmen: false,
      isShowAddCoach: false,
      isShowEditSportsman: false,
      safeItemSportsman: '',
      safeIndividualCategories: '',
      searchQuery: '',
      debounceTimer: null // Таймер для реализации дебаунса
    };
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      allSportsmens: (state) => state.mandated.allSportsmens,
    }),
  },

  watch: {
    searchQuery(val) {
      this.debounceSearch(val);
    },
  },
  props: {
    listNominationInd: {
      type: Array,
      default() {
        return {};
      },
    },
  },
  methods: {
    editSportsmen(item) {
      this.openModal('isShowEditSportsman')
      this.safeItemSportsman = item
      // this.isShowEditSportsman === !this.isShowEditSportsman
      // this.$emit("editSportsmen", this.item_sportsmen);
    },
    debounceSearch(fio) {
      clearTimeout(this.debounceTimer); // Очищаем таймер, если пользователь продолжает ввод
      this.debounceTimer = setTimeout(() => {
        this.performFioSearch(fio); // Вызываем функцию поиска после 300 мс
      }, 300); // Задаем задержку 300 мс
    },
    performFioSearch(fio) {
      if (fio) {
        this.$store.dispatch(actionTypesMandated.searchSportsmen,{surnameAndName:fio, competitionId: this.$route.params.id});
      } else {
        this.getSportsmen(this.safeIndividualCategories)
      }
    },
    // performFioSearch(fio) {
    //   if (fio !== "") {
    //     this.safeIndividualCategories = {};
    //     this.$store.dispatch(actionTypesMandated.searchSportsmen, fio);
    //   }
    //   // else {
    //   //   this.$store.dispatch(actionTypesMandated.getAllDoctors);
    //   // }
    // },
    // safeNewCategory(category) {
    //   console.log('новая категория', category)
    // },
    deleteSportsmen() {
      this.$emit("deleteSportsmen", this.item_sportsmen);
    },

    openModal(modalProperty) {
      this[modalProperty] = true;
      document.body.classList.add('no-scroll');
    },
    closeModal(modalProperty) {
      // console.log('modal close',modalProperty )
      this[modalProperty] = false;
      // Проверяем, нужно ли оставить скролл заблокированным (если есть другие открытые модальные окна)
      if (!this.anyModalOpen()) {
        document.body.classList.remove('no-scroll');
      }
    },
    anyModalOpen() {
      // Проверяем, открыто ли еще какое-либо модальное окно
      return this.isShowAddSportsmen || this.isShowModifSportsmen || this.isShowAddCoach ||
          this.isShowModifCoach || this.isShowAddGroup || this.isShowModiGroup ||
          this.isShowModiJuj || this.isShowAddJuj || this.isShowDelete;
    },
    getSportsmen(category) {
      this.searchQuery = ''
      this.safeIndividualCategories = category;
      // получение спортсменов по id категории
      this.dataIdToIndSportsmen = {
        categoryId: category.id,
        competitionId: this.$route.params.id,
      };
      this.$store
        .dispatch(
          actionTypesMandated.getAllSportsmenIndCateg,
          this.dataIdToIndSportsmen
        )
        .then(() => {

        })
        .catch((er) => {
          console.log(er, "Error");
        });
    },
  },
};
</script>

<style></style>
