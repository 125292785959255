<template>
  <div class="custom-select-start">
    <div class="custom-select-start-box" @click="toggleDropdown">
      <label class="custom-select-start-label">Выберите тип</label>
      <span class="custom-select-start-selected">{{ selectedOption.label }}</span>
      <span class="custom-select-start-arrow" :class="{ open: isOpen }">
        <img src="../../assets/svg/ArrowDown.svg" alt="ArrowDown">
      </span>
    </div>
    <ul v-if="isOpen" class="custom-select-start-dropdown">
      <li
          v-for="(option, index) in options"
          :key="index"
          @click="selectOption(option)"
          :class="{ active: option.key === selectedOption.key }"
      >
        {{ option.label }}
      </li>
    </ul>
  </div>
</template>

<script>
  import { actionTypesStartList } from '@/store/modules/createStartList'

  export default {
    name: "AppCardPerformanceSelectType",
    props: {
      flowId: Number,
      selectedDate: String,
    },
    data() {
      return {
        isOpen: false,
        selectedOption: { label: "Без вертушки", key: "noSpinner" },
        options: [
          { label: "Без вертушки", key: "noSpinner" },
          { label: "С вертушкой по видам", key: "withViewSpinner" },
          { label: "Вертушка одного вида", key: "withViewSpinnerOneType" }
        ]
      };
    },
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      },
      getStartList() {
        this.$store
            .dispatch(actionTypesStartList.getStartListPerformance, {
              competitionId: this.$route.params.id,
              startDate: this.selectedDate,
            })
            .then(() => {})
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      selectOption(option) {
        this.selectedOption = option;
        this.$store
            .dispatch(actionTypesStartList.changeProtocolType, {
              flowId: this.flowId,
              protocolLocationType: option.key,
            })
            .then(() => {
              this.getStartList();
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
        // this.$emit('selectProtocolType', option.key);
        this.isOpen = false;
      },
      closeDropdown(event) {
        if (!this.$el.contains(event.target)) {
          this.isOpen = false;
        }
      }
    },
    mounted() {
      document.addEventListener("click", this.closeDropdown);
    },
    beforeDestroy() {
      document.removeEventListener("click", this.closeDropdown);
    }
  };
</script>

<style scoped>
</style>
