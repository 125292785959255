import axios from "@/api/axios";

const getAllInfoMandated = (idCompetition) => {
  return axios.get(`/get/mandate/${idCompetition}`);
};
const getAllSportsmenIndCateg = (info) => {
  return axios.get(
    `/get/mandate/all_sportsmen_by_individual_category/${info.categoryId}/${info.competitionId}`
  );
};
const getAllGroupCateg = (info) => {
  return axios.get(
    `/get/mandate/all_sportsmen_by_group_category/${info.categoryId}/${info.competitionId}`
  );
};
const getAllTrioCateg = (info) => {
  return axios.get(
      `/get/mandate/all_sportsmen_by_trio_category/${info.categoryId}/${info.competitionId}`
  );
}
const getAllDuoCateg = (info) => {
  return axios.get(
      `/get/mandate/all_sportsmen_by_duo_category/${info.categoryId}/${info.competitionId}`
  );
}
const sortition = (id) => {
  return axios.post(`draw/sportsman?competitionId=${id}`);
};
const changeStatusPerformance = (sportsmenId) => {
  return axios.post(`/admin/change/sportsmen/status/${sportsmenId}`);
}
const changeCategorySportsman = (result) => {
  return axios.post(`/admin/change/sportsmen/category/${result.sportsmenId}/${result.categoryId} `);
}
const changeCategoryTrio = (result) => {
  return axios.post(`admin/change/trio-category/${result.groupId}/${result.categoryId} `);
}
const editPerformance = (data) => {
  return axios.post(`/admin/change/sportsmen/data-and-coach`, data);
}
//
const editGroupPerformance = (data) => {
  return axios.post(`/admin/change/group-data-coaches`, data);
}

const changeStatusPerformanceGroup =(groupId) => {
  return axios.post(`admin/change/group-status/${groupId} `);
}
const changeCategoryGroup = (result) => {
  return axios.post(`/admin/change/group-category/${result.groupId}/${result.categoryId} `);
}
const changeCategoryDuo = (result) => {
  return axios.post(`/admin/change/duo-category/${result.groupId}/${result.categoryId} `);
}
const changeStatusPerformanceTrio = (groupId) => {
  return axios.post(`admin/change/trio-status/${groupId} `);
}
const changeStatusPerformanceDuo = (groupId) => {
  return axios.post(`admin/change/duo-status/${groupId} `);
}
const addSportsmanToCategory = (data) => {
  return axios.post(`/add/mandate/sportsmen`, data);
}
const addGroupToCategory = (data) => {
  return axios.post(`/mandate/registration-new-group`, data);
}
// addTrioToCategory
const addTrioToCategory = (data) => {
  return axios.post(`/mandate/registration-new-trio`, data);
}

const addDuoToCategory = (data) => {
  return axios.post(`/mandate/registration-new-duo`, data);
}
const addJudgeToCompetition = (data) =>{
  return axios.post(`/mandate/registration-new-judge`, data);
}


const searchSportsmen = (data) => {
  return axios.get(`/get/mandate/search/sportsmen/${data.surnameAndName}/${data.competitionId}`);
}

const searchGroup = (data) => {
  return axios.get(`/get/mandate/search/group/${data.surnameAndName}/${data.competitionId}`);
}

export default {
  getAllInfoMandated,
  getAllSportsmenIndCateg,
  getAllGroupCateg,
  getAllTrioCateg,
  getAllDuoCateg,
  sortition,
  changeStatusPerformance,
  changeCategorySportsman,
  editPerformance,
  changeStatusPerformanceGroup,
  changeStatusPerformanceTrio,
  changeCategoryGroup,
  addSportsmanToCategory,
  addGroupToCategory,
  searchSportsmen,
  editGroupPerformance,
  addJudgeToCompetition,
  addTrioToCategory,
  addDuoToCategory,
  changeCategoryTrio,
  changeCategoryDuo,
  changeStatusPerformanceDuo,
  searchGroup,
};
