<template>
  <div class="main-content start-protocol-page">
    <!-- Заголовок страницы -->
    <Header message="Создание стартового протокола" />
    <div class="loader-overlay" v-if="isLoading">
      <div class="loader" >
        <LottieAnimation
            ref="anim"
            :animationData="require('@/assets/lottie/loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>
    </div>
    <div class="form-card">
      <form v-if="infoStartList && infoStartList !== null">
        <div class="form-group">
        <div class="custom-select-start">
          <div class="custom-select-start-box">
            <label class="custom-select-start-label">Соревнования</label>
            <input
                v-model="infoStartList.competitionTitle"
                type="text"
                placeholder="Введите название"
                class="custom-select-start-input"
            />
          </div>
        </div>
        </div>
        <div class="form-group-block">
          <div class="form-group-block__row">
            <div class="form-group-block__item">
              <AppSelectCustom  :all-dates="infoStartList.dates"  @update:selectedDate="handleDateChange"
                                @update:selectedTime="handleTimeChange"/>
            </div>
            <div class="form-group-block__item">
              <div class="" style="display: flex;align-items: center; justify-content: space-between;">
              <AppInputRound class="flex50"/>

              <div class="form-group-btn-item ">
                <button class="competition-btn competition-btn--view" type="button" style="text-align: center" @click="createStartProtocol">
<!--                  <img-->
<!--                      class="competition__item-btn-img"-->
<!--                      src="../assets/svg/download.svg"-->
<!--                      alt="download"-->
<!--                  />-->
                  Скачать протокол
                </button>
              </div>
              </div>
            </div>
            <div class="">
              <button class="competition-btn competition-btn--menu" type="button">
                <img
                    class="competition__img"
                    src="../assets/svg/settings.svg"
                    alt="download"
                />
                <span class="competition-btn__tooltip">Настройка экспорта</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- Карточка для базовых данных соревнования -->
    <!-- Tab меню для выбора блока -->
<CustomTabs @tabChange="handleTabChange" />

<!--    <div class="tab-menu">-->
<!--&lt;!&ndash;      <button :class="['tab-button', { active: activeTab === 'noSpinner' }]" @click="activeTab = 'noSpinner'">Без вертушки</button>&ndash;&gt;-->
<!--      <button :class="['tab-button', { active: activeTab === 'withSpinner' }]" @click="activeTab = 'withSpinner'">Создание потоков</button>-->
<!--      <button :class="['tab-button', { active: activeTab === 'break' }]" @click="activeTab = 'break'">Перерыв</button>-->
<!--    </div>-->
    <!-- Выбор групповых или обычных номинаций -->
<!--    <div class="nomination-toggle">-->
<!--      <label>-->
<!--        <input type="radio" v-model="isGroupNomination" value="INDIVIDUAL"  />-->
<!--        Индивидуальные номинации-->
<!--      </label>-->
<!--      <label>-->
<!--        <input type="radio" v-model="isGroupNomination" value="GROUP" />-->
<!--        Групповые номинации-->
<!--      </label>-->
<!--    </div>-->
    <!-- Блок "Без вертушки" -->
    <AppCreateFlowNoSpinner
        v-if="activeTab === 'noSpinner'"
        :listNomination="currentNomination"
        :about_start_list="this.form"
        :selectedDate = "this.selectedDate"
        :isGroupNomination="this.isGroupNomination"
    />

    <!-- Блок "С вертушкой ДЕЙСТВУЮЩИЙ" -->
    <AppCreateFlowWithSpinner
        v-if="activeTab === 'withViewSpinner' "
        :listNomination="currentNomination"
        :about_start_list="this.form"
        :selectedDate="this.selectedDate"
        :selectedTime="this.selectedTime"
        :isGroupNomination="this.isGroupNomination"
    />
    <AppCreateFlowWithSpinnerOneType
        v-if="activeTab === 'withViewSpinnerOneType' "
        :listNomination="currentNomination"
        :about_start_list="this.form"
        :selectedDate="this.selectedDate"
        :selectedTime="this.selectedTime"
        :isGroupNomination="this.isGroupNomination"/>

    <!-- Блок "Перерыв" -->
    <AppCreateFlowBreak v-if="activeTab === 'break'"  :about_start_list="this.form" :selectedDate="selectedDate"/>

    <!-- Карточки представления потоков -->
   <template v-if="listPerformance && selectedDate !== null">
    <CardPerformance  v-for="(item, index) in listPerformance" :item_flow="item"  :selectedDate="selectedDate"  :idx="index" :key="index" :about_start_list="form" :allList="listPerformance" />
   </template>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Header from '@/components/Header.vue';
  import { actionTypesStartList } from '@/store/modules/createStartList';
  import AppCreateFlowNoSpinner from '@/components/Startlist/CreateFlowNoSpinner.vue';
  import AppCreateFlowWithSpinner from '@/components/Startlist/CreateFlockViewSpinner.vue';
  import AppCreateFlowBreak from '@/components/Startlist/CreateFlowBreak.vue';
  import CardPerformance from '@/components/Startlist/CardPerformance.vue';
  import axios from 'axios'
  import AppSelectCustom from '@/components/Startlist/SelectCustom.vue'
  // import AppInputTime from '@/components/Startlist/InputTime.vue'
  import AppInputRound from '@/components/Startlist/InputRound.vue'
  import CustomTabs from '@/components/Startlist/Tabs.vue'
  import LottieAnimation from 'lottie-web-vue'
  import AppCreateFlowWithSpinnerOneType from '@/components/Startlist/CreateFlowWithSpinnerOneType.vue'

  export default {
    components: {
      AppCreateFlowWithSpinnerOneType,
      LottieAnimation,
      CustomTabs,
      AppInputRound,
      // AppInputTime,
      AppSelectCustom,
      CardPerformance,
      AppCreateFlowBreak,
      AppCreateFlowWithSpinner,
      AppCreateFlowNoSpinner,
      Header,
    },
    name: "NewCreateStartList",
    data() {
      return {
        form: {
          nameStartList: 'Тест',
          startDateAndTime: "2025-01-28T09:00",
          roundingTime: true,
          numberOfAthletesInStream: 1,

        },
        activeTab: 'noSpinner',
        isGroupNomination: 'GROUP', // Управление между обычными и групповыми номинациями
        selectedDate: null,
        selectedTime: ""

      };
    },
    computed: {
      ...mapState({
        listNomination: (state) => state.createStartList.listNomination,
        listGroupNomination: (state) => state.createStartList.listGroupNomination,
        listPerformance: (state) => state.createStartList.listPerformance,
        infoStartList: (state) => state.createStartList.infoStartList,
        isLoading: (state) =>  state.createStartList.isLoading
      }),
      // Выбор текущей номинации в зависимости от выбранного режима
      currentNomination() {
        return this.isGroupNomination === 'GROUP' ? this.listGroupNomination : this.listNomination;
      },
    },
    methods: {
      handleDateChange(newDate) {
        console.log('Дата изменилась', newDate)
        this.selectedDate = newDate;
      },
      handleTimeChange(newTime) {
        this.selectedTime = newTime;
      },
      handleTabChange(value) {
        this.activeTab = value;
        console.log("Выбранный таб:", value);
      },
      getPerformance() {
        this.$store
            .dispatch(actionTypesStartList.getStartListPerformance, {
              competitionId: this.$route.params.id,
              startDateTime: this.form.startDateAndTime,
            })
            .then(() => {})
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      createStartProtocol() {
        this.endData = {
          competitionId:  this.$route.params.id,
          startDate: this.selectedDate,
          startTime: this.selectedTime,
        };
        axios
            .post(`/export/protocol`, this.endData, {
              responseType: "blob",
            })
            .then((response) => {
              console.log(response);
              const url = URL.createObjectURL(
                  new Blob([response.data], {
                    type: "application/vnd.ms-excel",
                  })
              );
              var fileName = `Протокол.xlsx`;
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();
            });
      },
    },
    mounted() {
      // Получение информации о номинациях
      this.$store
          .dispatch(actionTypesStartList.getNominationsList, this.$route.params.id)
          .then(() => {})
          .catch((er) => {
            console.log("Ошибка", er);
          });
      // getNominationGroupList
      this.$store
          .dispatch(actionTypesStartList.getNominationGroupList, this.$route.params.id)
          .then(() => {})
          .catch((er) => {
            console.log("Ошибка", er);
          });

      this.$store
          .dispatch(actionTypesStartList.allStartLists, this.$route.params.id)
          .then(() => {})
          .catch((er) => {
            console.log("Ошибка", er);
          });


    },
  };
</script>

<style scoped>
.main-content {
  padding: 20px;
}

.nomination-toggle {
  margin: 20px 0;
  display: flex;
  gap: 20px;
}

.nomination-toggle label {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
}
</style>
